import React, { useEffect, useRef, useState } from 'react'
import { useGetEventDetailMutation } from '../features/property/eventSlice'
import { Link ,useLocation } from 'react-router-dom'
import { PhotoProvider, PhotoView } from 'react-photo-view';


function EventDetail() {

const [event,setEvent]=useState()
const [getevent,{isLoading}]=useGetEventDetailMutation()
const [show,setShow]=useState(false)
const dropdownRef=useRef(null)
const [imagelength,setImageLength]=useState()

const location = useLocation();
const currentUrl = window.location.origin + location.pathname;


const handleGetEvent=async()=>{
    const id=localStorage.getItem("event_id")
    const response= await getevent(id)
    console.log(response.data)
    setEvent(response.data)
    setImageLength(response.data.other_image.slice(0,5).length)

}

useEffect(()=>{
handleGetEvent()

},[])



const shareLinks = [
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-facebook fs-base opacity-75 me-2',
      text: 'Facebook',
    },
    {
      href: `https://twitter.com/intent/tweet?text=Check%20this%20out!&url=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-twitter fs-base opacity-75 me-2',
      text: 'Twitter',
    },
    {
      href: `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-whatsapp fs-base opacity-75 me-2',
      text: 'WhatsApp',
    },
    {
      href: `https://telegram.me/share/url?url=${encodeURIComponent(currentUrl)}&text=Check%20this%20out!`,
      iconClass: 'fi-telegram fs-base opacity-75 me-2',
      text: 'Telegram',
    },
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    timeZoneName: 'short' 
  };
  

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <section class="container pt-5 mt-5">
   
    <nav class="mb-3 pt-md-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <Link to='/'>Home</Link>
            </li>
            <li class="breadcrumb-item">
                <Link to='/events' >
                    Interesting Places 
                </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                { event&& event.title}
            </li>
        </ol>
    </nav>
    <div class="d-sm-flex align-items-center justify-content-between mb-4 pb-sm-2">
        <h1 class="h2 me-3 mb-sm-0"> { event&& event.title}</h1>
        <div class="text-nowrap">
     
            <div class="dropdown newdrpdown d-inline-block" data-bs-toggle="tooltip" title="" data-bs-original-title="Share">
                <button onClick={()=>{setShow(!show)}} class="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2" type="button" data-bs-toggle="dropdown">
                    <i class="fi-share"></i>
                </button>
                <div ref={dropdownRef} className={`dropdown-menu dropdown-menu-end my-1  ${show?"show":""}`}>
      {shareLinks.map((link, index) => (
        <a key={index} href={link.href} className="dropdown-item" target="_blank" rel="noopener noreferrer">
          <i className={link.iconClass}></i>
          {link.text}
        </a>
      ))}
    </div>
            </div>
        </div>
    </div>


    <section class="container overflow-auto mb-5" data-simplebar="init">
		<PhotoProvider 
            speed={() => 800}
  easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
      >
    <div class="row g-2 g-md-3 gallery" data-thumbnails="true" style={{minWidth: '30rem'}}>
			<div class="col-12">
      <PhotoView     
             src={`https://dev-api.shortstaybuddy.com/${event && event.featured_image}`}>
				<div class="gallery-item rounded rounded-md-3"  data-sub-html="<h6 class=&quot;fs-sm text-light&quot;>Sand Box</h6>" data-lg-id="85f2ac90-871a-46a1-b7ee-1d0186f40b2b">
        
					<img style={{height: '650px', objectFit: 'cover'}} src={`https://dev-api.shortstaybuddy.com/${event && event.featured_image}`} alt="Gallery thumbnail"/>
          
				</div>
        </PhotoView>

        <div className=" row row-cols-4 mt-3  g-2 g-md-3">
            {
            event && 
        event.other_image[0]&&
        event.other_image.map((file,index)=>{

          return (
          

            <>
            <div className="col">

            {
              index>3?
              <PhotoView     
             src={`https://dev-api.shortstaybuddy.com/${file.image}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px',display:"none", objectFit: 'cover' }} 
                src={`https://dev-api.shortstaybuddy.com/${file.image}`}
                alt="Gallery thumbnail" 
              />
                           

            </div>
            
            </PhotoView>
             :
             
              <PhotoView     
             src={`https://dev-api.shortstaybuddy.com/${file.image}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px', objectFit: 'cover' }} 
                src={`https://dev-api.shortstaybuddy.com/${file.image}`}
                alt="Gallery thumbnail" 
              />
                           

              
              {
                index >=3 &&imagelength>4?
                <span class="gallery-item-caption fs-base" style={{cursor:"pointer"}}>
                
                +{imagelength-4} <span class='d-none d-md-inline'>photos</span>
                
                </span>
                :""
              }
            </div>
            
            </PhotoView>
            }
               
      
              
              </div>

            </>
         ) })
          
          }
         
            </div>
			</div>


     
		</div>
    </PhotoProvider>
</section>
  

    <section class="container mt-5 ">
		<div class="row">
			<div class="col-md-12 mb-md-0 mb-3">
				<div class="card py-2 px-sm-4 px-3 shadow-sm">
					<div class="card-body mx-n2">
						
						<div class=" row  mb-3 pb-3 border-bottom">
							<div className=' col-sm-7 d-flex align-items-start'>
							<div class="ps-2 ms-1">
								<h3 class="h5 mb-2"> {event&&event.title}</h3>
								<ul class="list-unstyled d-flex flex-wrap fs-sm">
									
									<li class="me-2 mb-1 pe-1">
										<i class="fi-wallet mt-n1 me-1 align-middle opacity-70"></i>
                    ₵{event&&event.price} 
																			</li>
									<li class="me-2 mb-1 pe-1">
										<i class="fi-map-pin mt-n1 me-1 align-middle opacity-70"></i>
                                        {event&&event.location}
 
									</li>
                       
								</ul>
							</div>
              </div>
              <div className='col-sm-5' >             <button className='btn btn-primary btn-sm mt-2 '><i className='fi-route '></i> Get Directions</button>

              </div>
						</div>

            <div class="ps-2 ms-1 w-full  mb-3 pb-3 border-bottom" style={{width:"100%"}}>
            <div  class="row ">
									
									<div class="col-md-7 fs-dm ">
										<strong className='me-2'>Category:</strong>
                    {
      event && event.category_detail.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <span>{item.title}</span>
            {index !== event.category_detail.length - 1 && ", "}
          </React.Fragment>
        );
  })
}

																			</div>
                                      <div className='col-md-5 border-start '>
                                        <div className="ps-2 fs-sm d-flex flex-column ">
                                        <div><span><strong className='me-2'>Opening date:</strong>{event && `${new Date(event.start_date).toLocaleString('en-US', options)}` }</span></div>
                                      <div><span><strong className='me-3'>Closing date:</strong>{event && `${new Date(event.end_date).toLocaleString('en-US', options)}`}</span></div>


                                        </div>
                                      </div>
								
                  
								</div>


</div>
					
						<div class="mb-3 ps-2 pb-3 border-bottom ">
							<h4 class="h5 mb-2">Description</h4>
							<p class="fs-sm text-muted mb-0"></p><p>

                            {event&&event.description}

                            </p><p></p>
						</div>
						
						
					</div>
				</div>
			</div>
			
			
		</div>
	</section>

    <ul class="nav nav-pills border-bottom pb-3 mb-4">
    </ul>
</section>
  )
}

export default EventDetail