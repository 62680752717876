import {React,useState,useEffect,useRef}from 'react'
import logo from "../asserts/img/logo/site_logo.png"
import SigninModal from './landingcomponents/signinModal';
import { Link,useLocation } from 'react-router-dom';
import { logOut } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useGetprofileMutation } from '../features/auth/authApiSlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { setUserData } from '../features/auth/authSlice';
import avatar1 from '../asserts/img/avatars/14.png'
import { useChangeToHostMutation } from '../features/auth/authApiSlice';

function Navbar({token}) {

  
const [showmodal,setshowmodal]=useState(false)
const [success,setSuccess]=useState(false)

const [showbackdrop,setshowbackdrop]=useState(false)
const [hasScrolled,setHasScrolled]=useState(false)
const [menushow,setMenushow]=useState(false)
const [account ,setAccountShow]=useState(false)
const [pages,setPagesShow]=useState(false)
const [getprofile,{isLoading}]=useGetprofileMutation()
const dispatch =useDispatch()
const location = useLocation();
const isHomeActive = location.pathname === "/";
const isPropertiesActive = location.pathname === "/properties";
const isExperiencesActive = location.pathname === "/experiences";
const isHelpActive = location.pathname === "/help";
const modalRef = useRef(null)
const [showmodal1,setshowmodal1]=useState(false)

const [updateuser,{isLoading:isLoadingUpdate}]=useChangeToHostMutation()


const handleUpdateUser = async()=>{
	try{

		const response = await updateuser({}).unwrap();
		console.log(response)
		setSuccess(true)
		setTimeout(() => {
			setshowmodal1(false);
			setSuccess(false);
			window.location.reload();
		  }, 3000);

		
		

	}
	catch(e){
		console.log(e)
	}
}


useEffect(() => {
	function handleClickOutside(event) {
		

		if (modalRef.current && !modalRef.current.contains(event.target)) {
		  setshowmodal1(false)
	  }
	}

	document.addEventListener('mousedown', handleClickOutside);
	return () => {
		document.removeEventListener('mousedown', handleClickOutside);
	};

}, []); 



const [userData,setUserData2]=useState({
  "first_name":"" ,
  "last_name":"",
  'email':"",
  "phone_number":"",
  'role':''
})

const isAccountActive = /^\/dashboard\/.+/.test(location.pathname);




const handleLogout=()=>{
  dispatch(logOut())
  
  

}

const handleGetProfile=async()=>{

  try {
    const profiledata=await getprofile()
    
    setUserData2({
      
      ...userData,
      'first_name':profiledata.data.first_name,
      'last_name':profiledata.data.last_name,
      'email':profiledata.data.email,
      'phone_number':profiledata.data.phone_number,
      'role':profiledata.data.role,
      "profile":profiledata.data.profile

    })

    dispatch(
      setUserData(profiledata.data)
    )
  }catch(e){
  console.log(e)
}

 


}
 useEffect(()=>{
  if (token){

    handleGetProfile()
    
    
    }
 },[])








  useEffect(() => {
    const handleScroll = () => {
        // Check if the scroll position is more than zero
        const isScrolled = window.scrollY > 0;
        
        setHasScrolled(isScrolled);
    };

    // Add scroll event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };


}, []);



const toggleModal = () => {
   setshowmodal(!showmodal)
   setshowbackdrop(!showbackdrop)
};


  return (
<>
<div className={`backdrop  ${showmodal1?"show":""} `}>
      <div  class="modal fade show open-modal" id="modalDefault" tabindex="-1" style={{display: 'block'}} aria-modal="true" role="dialog">
            <div ref={modalRef} class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Switching to a host account </h4>
                  <button  onClick={()=>{setshowmodal1(!showmodal1)}} class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
				{
					success?
					<div class="alert alert-success" role="alert">
  your account is now a host account


</div>
					:""
				}
                  <p>
                  When you switch to a host account, you’ll be able to list your properties for rent on our platform. It’s a simple way to manage your properties and easily connect with potential guests
				  </p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal "  onClick={()=>{setshowmodal1(!showmodal1)}}>Cancel</button>

                  {
                    isLoadingUpdate?
                    <button  class="btn btn-primary btn-shadow btn-sm" type="button">
                    <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Switching...
                      </button>

                    :
                    <button class="btn btn-primary btn-shadow btn-sm" onClick={handleUpdateUser} type="button">Switch</button>

                  }
                </div>
              </div>
            </div>
          </div>
          </div>

  
    <header className={`navbar navbar-expand-lg navbar-light fixed-top  ${hasScrolled?'navbar-stuck':''}`}>


        <div className="container"><Link to='/' className="navbar-brand me-3 me-xl-4" >
        <img className="d-block" src={logo} width="116" alt="Finder"/></Link>
          <button onClick={()=>{setMenushow(!menushow)}} className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
          {
            token?
            <div className="dropdown order-lg-3">
      <span  style={{width:"40px",height:"50px"}} className="d-inline-block py-1" >
      
      {userData.profile ?         <img className="rounded-circle" src={userData.profile}  style={{width:'100%',height:'100%',objectFit:"cover"}} alt="User"/>
:        <img className="rounded-circle" src={avatar1} width="40" alt="User"/>
}
      </span>
      <div className="dropdown-menu dropdown-menu-end " >
        <div className="d-flex align-items-start border-bottom px-3 py-1 mb-2" style={{width: "16rem"}}>
        <span  style={{width:"40px",height:"40px"}}>
        {userData.profile ?         <img className="rounded-circle" src={userData.profile} style={{width:'100%',height:'100%',objectFit:"cover"}}   alt="User"/>
:        <img className="rounded-circle" src={avatar1} width="40" alt="User"/>
}
     
        </span>
     <div className="ps-2">
          {
            isLoading? <Skeleton/>: userData?
          
            <h6 style={{wordBreak: 'break-all'}}   className="fs-base mb-0">
            {`${userData.first_name} ${userData.last_name}`}</h6>:""
          }
            
            <div style={{wordBreak: 'break-all'}} className="fs-xs py-2">
            {
              isLoading? <Skeleton/>:userData?userData.phone_number:""
            }
            <br/>
            {
              isLoading? <Skeleton/>:userData? userData.email:""}</div>
          </div>
        </div>
        <Link to="/dashboard/personal-info" className="dropdown-item">
          <i className="fi-user opacity-60 me-2"></i>
          Personal Info
        </Link>
        <Link  to='/dashboard/password-security' className="dropdown-item">
          <i className="fi-lock opacity-60 me-2"></i>
          Password & Security
        </Link>
        <Link to='/dashboard/recent-booking' className="dropdown-item" >
						<i className="fi-bookmark opacity-60 me-2"></i>
						Recent booking
					</Link>
          {
            userData&& userData.role==='user'?'': <Link to='/dashboard/my-properties' className="dropdown-item">
          <i className="fi-list opacity-60 me-2"></i>
          My Properties
        </Link>
          }
        
        <Link to="/dashboard/wishlist" className="dropdown-item">
          <i className="fi-heart opacity-60 me-2"></i>
          Wishlist
        </Link>
        {
          userData&&userData.role==='user'?'':<Link  to="/dashboard/reviews"  className="dropdown-item">
          <i className="fi-star opacity-60 me-2"></i>
          Reviews
        </Link> 
        }
        
        <Link to="/dashboard/notifications" className="dropdown-item">
          <i className="fi-bell opacity-60 me-2"></i>
          Notifications
        </Link>
        <div className="dropdown-divider"></div>
        <Link to='/help' href="#" className="dropdown-item">Help</Link>
        <a style={{cursor:"pointer"}} onClick={handleLogout} className="dropdown-item">Sign Out</a>
      </div>
    </div>
    
    
    :
    <button  onClick={toggleModal} className="btn btn-sm text-primary d-none d-lg-block order-lg-3" data-bs-toggle="modal"><i className="fi-user me-2"></i>Sign in</button>

          }
          
          {
            token?

            userData&&userData.role==='user'? 
            <button  onClick={()=>{setshowmodal1(true)}}  className="btn btn-primary btn-sm ms-2 order-lg-3">
				
				
				<i className="fi-real-estate-house me-2"></i>
				become a host
				
			</button>:
            <span  className="btn btn-outline-primary btn-sm ms-2 order-lg-3" style={{'pointerEvents': 'none'}}>
				
				
				<i className="fi-cash me-2"></i>
				₵0.00
				
			</span>: <Link to="/register" className="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3"  ><i className="fi-user-plus me-2"></i><span className='d-none d-sm-inline'>Sign Up</span></Link>         

          }
          <div className={`collapse navbar-collapse order-lg-2 navbar-nav-scroll ${menushow?"show":""}`} id="navbarNav">
            <ul className="navbar-nav navbar-nav-scroll" style= {{maxHeight: '35rem'}}>
              
              <li className={`nav-item dropdown ${isHomeActive?"active":""}`}><Link to="/" className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false">Home</Link>
            
              </li>
              <li className={`nav-item dropdown ${isPropertiesActive?"active":""}`}><Link to='/properties' className="nav-link " href="find_accomodation" role="button" data-bs-toggle="dropdown" aria-expanded="false">Find Accommodation</Link>
                
              </li>
              <li className={`nav-item dropdown ${isExperiencesActive?"active":""}`}><Link to="/experiences" className="nav-link  " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Experiences</Link>
           
              </li>
              <li className="nav-item dropdown">
              {
                token?						<a  onClick={()=>{setAccountShow(!account)}} className={`nav-link dropdown-toggle ${isAccountActive?"active":""}`} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Account
						</a>:''
              }

						<ul className={`dropdown-menu ${account?"show":""}` }>
							<li>
								<Link className="dropdown-item" to='/dashboard/personal-info' >
									Personal Info
								</Link>
							</li>
							<li>
								<Link to="/dashboard/recent-booking" className="dropdown-item" >
									Recent booking
								</Link>
							</li>
							<li>
								<Link to='/dashboard/password-security' className="dropdown-item">
									Password &amp; Security
								</Link>
							</li>
															<li>
                             { userData&& userData.role==='user'?'':
									<Link to='/dashboard/my-properties' className="dropdown-item" >
										My Properties
									</Link>
                             }
								</li>
														<li>
								<Link to='/dashboard/wishlist' className="dropdown-item" >
									Wishlist
								</Link>
							</li>
							<li>
             { userData&& userData.role==='user'?'':
								<Link to='/dashboard/reviews' className="dropdown-item" >
									Reviews
								</Link>
             }
							</li>
							<li>
								<Link to='/dashboard/notifications' className="dropdown-item">
									Notifications
								</Link>
							</li>
						</ul>
					</li>
            
              <li className="nav-item dropdown "   > <a onClick={()=>{setPagesShow(!pages)}} className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Pages</a>
                <ul className={`dropdown-menu ${pages?"show":""}`}>
                
                  <li><Link to='/posts' className={` dropdown-item `} href="city-guide-about.html">Our Blog</Link></li>
          

                  <li><Link to='/contact' className="dropdown-item" href="city-guide-contacts.html">Contacts</Link></li>
                  <li><Link to='/FAQs' className="dropdown-item" href="city-guide-about.html">FAQs</Link></li>

                </ul>
              </li>
              <li className={`nav-item dropdown ${isHelpActive?"active":""}`}><Link to="/help"   className="nav-link"  href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Help Center </Link>
           
           </li>
            </ul>
          </div>
        </div>
        <SigninModal changeshow={toggleModal}  show={showmodal} backdrop={showbackdrop} />

      </header>
      </>
  )
}

export default Navbar