import { apiSlice } from "../../app/api/apiSlice";
import { apiSlice1 } from "../../app/api/apiSlice1";


export const propertyApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getCategories:builder.mutation({
            query:()=>({
                url:'admin/property/category/',
                method: 'GET',
                
            })
        }),     
        getAmenities:builder.mutation({
            query:()=>({
                url:'admin/property/amenities/',
                method: 'GET',
                
            })
        }), 
        
        getPrevilege:builder.mutation({
            query:()=>({
                url:'admin/property/privilege/',
                method: 'GET',
                
            })
        }),  

        getRegions:builder.mutation({
            query:()=>({
                url:'admin/property/region/',
                method: 'GET',
                
            })
        }),
        addProperty:builder.mutation({
            query:(body)=>({
                url:'host/property/create/',
                method: 'POST',
                body:{...body},
                
            })
        }),
        getMyProperties:builder.mutation({
            query:(params)=>({
                url:'host/property/list/',
                method: 'GET',
                params:params,
                
            })
    }),
    getMyPropertyDetail:builder.mutation({
        query:(id)=>({
            url:`host/property/detail/${id}/`,
            method: 'GET',
            
        })
}),
    deleteProperty:builder.mutation({
        query:(id)=>({
            url:`host/property/detail/${id}/`,
            method: 'DELETE',
            
        })
}),



getAllProperties:builder.mutation({
    query:(params)=>({
        url:`admin/property/list/`,
        params:params,
        method: 'GET',
        
        
    })
}),
   

getPropertiesDetailUser:builder.mutation({
    query:(id)=>({
        url:`admin/property/${id}/`,      
        method: 'GET',
        
        
    })
}),
   





addToWishlist:builder.mutation({
    query:(body)=>({
        url:`client/wishlist/`,
        method: 'POST',
        body:{...body}
        
        
    })
}),

getWishlist:builder.mutation({
    query:()=>({
        url:`client/wishlist/`,
        method: 'GET',
        
        
        
    })
}),
addReview:builder.mutation({
    query:(body)=>({
        url:`host/property/review/`,
        method: 'POST',
        body:{...body}
        
        
        
    })
}),
getReviews:builder.mutation({
    query:(param)=>({
        url:`host/property/review/`,
        method: 'GET',
        params:param
        
        
        
        
    })
}),
addcontact:builder.mutation({
    query:(body)=>({
        url:`admin/enquiry/contact/`,
        method: 'POST',
        body:{...body}
        
        
        
        
    })
}),


getBlog:builder.mutation({
    query:()=>({
        url:`/admin/blog/`,
        method: 'GET',
        
        
        
        
        
    })
}),

getBlogDetail:builder.mutation({
    query:(id)=>({
        url:`/admin/blog/${id}`,
        method: 'GET',
        
        
        
        
        
    })
}),

getBlogCategories:builder.mutation({
    query:()=>({
        url:`/admin/blog/category/`,
        method: 'GET',
        
        
        
        
        
    })
}),



    
addProperty:builder.mutation({
    query:(formdata)=>({
        url:'host/property/create/',
        method: 'POST',
        body:formdata,
        formData:true
    
})
}),
editProperty:builder.mutation({
    query:({id,formdata})=>({
        url:`host/property/detail/${id}/`,
        method: 'PATCH',
        body:formdata,
        formData:true
        
    })
}),

bookProperty:builder.mutation({
    query:(body)=>({
        url:`client/book-property/`,
        method: 'POST',
        body:{...body},
        
        
    })
}),


cancelBooking:builder.mutation({
    query:(body)=>({
        url:`client/booking/cancel/`,
        method: 'POST',
        body:{...body},
        
        
    })
}),

bookingList:builder.mutation({
    query:(param)=>({
        url:`client/booking/`,
        method: 'GET',
        params:param
        
        
        
        
        
    })
    
}),

bookingListHost:builder.mutation({
    query:(param)=>({
        url:`host/property/booking/`,
        method: 'GET',
        params:param,
        
        
        
    })
    
}),

getBoookingDetail:builder.mutation({
    query:(id)=>({
        url:`host/property/booking/${id}/`,
        method: 'GET',
      
        
        
        
    })
    
}),



changeBookingHost:builder.mutation({
    query:({id,body})=>({
        url:`host/property/booking/${id}/`,
        method: 'PATCH',
        body:{...body},
        
        
        
    })
    
}),


addNewsletter:builder.mutation({
    query:(body)=>({
        url:`admin/manage-site/newsletter/`,
        method: 'POST',
        body:{...body},
        
        
        
    })
    
}),

getSiteLayout:builder.mutation({
    query:()=>({
        url:`admin/manage-site/carousel/`,
        method:'GET',
       
        
    })
}),






}),

    



})
  



export const {
 useGetCategoriesMutation,
 useGetAmenitiesMutation,
 useGetPrevilegeMutation,
 useGetRegionsMutation,
 
 useGetMyPropertiesMutation,
 useDeletePropertyMutation,
 useGetMyPropertyDetailMutation,
 
 useGetAllPropertiesMutation,
 useAddToWishlistMutation,
 useGetWishlistMutation,
 useAddReviewMutation,
 useGetReviewsMutation,
 useAddcontactMutation,
 useGetPropertiesDetailUserMutation,
 useGetBlogMutation,
 useGetBlogCategoriesMutation,
 useGetBlogDetailMutation,
 useEditPropertyMutation,

 useAddPropertyMutation,
 useBookPropertyMutation,
 useCancelBookingMutation,
 useBookingListMutation,
 useBookingListHostMutation,
 useChangeBookingHostMutation,
 useAddNewsletterMutation,
 useGetBoookingDetailMutation,
 useGetSiteLayoutMutation,

}=propertyApiSlice;




















