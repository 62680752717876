import { apiSlice } from "../../app/api/apiSlice";


export const propertyApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        initiateTransaction:builder.mutation({
            query:(body)=>({
                url:'transaction/initiate-payment/',
                method: 'POST',
                body:{...body}
                
            })
        }),     

        getPayoutList:builder.mutation({
            query:()=>({
                url:'transaction/receipts/',
                method: 'GET',
            
                
            })
        }),   
        
        withdrawalHost:builder.mutation({
            query:(body)=>({
                url:'transaction/withdraw/',
                method: 'POST',
                body:{...body}
                
            })
        }),     
 
 

    



    })


})
  



export const {

useInitiateTransactionMutation,
useGetPayoutListMutation,
useWithdrawalHostMutation
}=propertyApiSlice;




















