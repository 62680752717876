import React from 'react'
import PageLoading from '../components/landingcomponents/pageLoader'
import Categoriescarousel from '../components/landingcomponents/categoriescarousel'
import SidebarAccomodation from '../components/sidebarAccomodation'
import image6 from "../asserts/img/real-estate/catalog/06.jpg"
import image7 from "../asserts/img/real-estate/catalog/07.jpg"
import image8 from "../asserts/img/real-estate/catalog/08.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

function TopLuxury() {

    
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe:true,
    touchMove:true,
    rows:1,
    infinite: false,
    arrows:true,
    infinite:true,
    prevArrow:<button type="button" data-controls="prev" tabindex="-1" aria-controls="tns3"><i class="fi-chevron-left"></i></button>,
    nextArrow:<button type="button" data-controls="next" tabindex="-1" aria-controls="tns3"><i class="fi-chevron-right"></i></button>,


  }
  return (
    <div className="container-fluid mt-5 pt-5 p-0">
    <Categoriescarousel/>
    <PageLoading/>
    <div className="row g-0 mt-n3">
    <SidebarAccomodation/>
    
              <div class="col-lg-8 col-xl-9 position-relative overflow-hidden pb-5 pt-4 px-3 px-xl-4 px-xxl-5">
              
                <div class="map-popup invisible" id="map">
                  <button class="btn btn-icon btn-light btn-sm shadow-sm rounded-circle" type="button" data-bs-toggle-class="invisible" data-bs-target="#map"><i class="fi-x fs-xs"></i></button>
                  <div class="interactive-map" data-map-options-json="json/map-options-real-estate-rent.json"></div>
                </div>
           
                <nav class="mb-3 pt-md-2" aria-label="Breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="real-estate-home-v1.html">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Luxury Properties</li>
                  </ol>
                </nav>
            
                <div class="d-sm-flex align-items-center justify-content-between pb-3 pb-sm-4">
                  <h1 class="h2 mb-sm-0">Luxury Properties</h1>
                </div>
              
                <div class="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
                  <div class="d-flex align-items-center flex-shrink-0">
                    <label class="fs-sm me-2 pe-1 text-nowrap" for="sortby"><i class="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
                    <select class="form-select form-select-sm" id="sortby">
                      <option>Newest</option>
                      <option>Popularity</option>
                      <option>Low - High Price</option>
                      <option>High - Low Price</option>
                      <option>High rating</option>
                      <option>Average Rating</option>
                    </select>
                  </div>
                  <hr class="d-none d-sm-block w-100 mx-4"/>
                  <div class="d-none d-sm-flex align-items-center flex-shrink-0 text-muted"><i class="fi-check-circle me-2"></i><span class="fs-sm mt-n1">148 results</span></div>
                </div>
               
                <div class="row g-4 py-4 ">
               
              
                <div className="col-sm-6 col-xl-4">
                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover" style={{height: '200px'}}><a className="img-overlay" href="real-estate-single-v1.html h-100" style={{height: '200px'}}></a>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"><span className="d-table badge bg-success mb-1">Verified</span></div>
                      <div className="content-overlay end-0 top-0 pt-3 pe-3">
                        <button className="btn btn-icon btn-light btn-xs text-primary rounded-circle" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to Wishlist"><i className="fi-heart"></i></button>
                      </div>
                      <Slider className='slider3 h-100' {...settings}><img src={image6} alt="Image"  style={{height: '100%', objectFit: 'cover', width: '100%'}}/><img src={image6} alt="Image"  style={{height: '200px', objectFit: 'cover', width: '100%'}}/>
                                            </Slider>
                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary">For rent</h4>
                      <h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" href="real-estate-single-v1.html">3-bed Apartment | 67 sq.m</a></h3>
                      <p className="mb-2 fs-sm text-muted">3811 Ditmars Blvd Astoria, NY 11105</p>
                      <div className="fw-bold"><i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>$1,629</div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"><span className="d-inline-block mx-1 px-2 fs-sm">3<i className="fi-bed ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block mx-1 px-2 fs-sm">2<i className="fi-bath ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block mx-1 px-2 fs-sm">2<i className="fi-car ms-1 mt-n1 fs-lg text-muted"></i></span></div>
                  </div>
                </div>
              
              
               
    
                   
                <div className="col-sm-6 col-xl-4">
                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover" style={{height: '200px'}}><a className="img-overlay" href="real-estate-single-v1.html h-100" style={{height: '200px'}}></a>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"><span className="d-table badge bg-success mb-1">Verified</span></div>
                      <div className="content-overlay end-0 top-0 pt-3 pe-3">
                        <button className="btn btn-icon btn-light btn-xs text-primary rounded-circle" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to Wishlist"><i className="fi-heart"></i></button>
                      </div>
                      <Slider className='slider3 h-100' {...settings}><img src={image7} alt="Image"  style={{height: '100%', objectFit: 'cover', width: '100%'}}/><img src={image7} alt="Image"  style={{height: '200px', objectFit: 'cover', width: '100%'}}/>
                                            </Slider>
                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary">For rent</h4>
                      <h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" href="real-estate-single-v1.html">3-bed Apartment | 67 sq.m</a></h3>
                      <p className="mb-2 fs-sm text-muted">3811 Ditmars Blvd Astoria, NY 11105</p>
                      <div className="fw-bold"><i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>$1,629</div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"><span className="d-inline-block mx-1 px-2 fs-sm">3<i className="fi-bed ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block mx-1 px-2 fs-sm">2<i className="fi-bath ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block mx-1 px-2 fs-sm">2<i className="fi-car ms-1 mt-n1 fs-lg text-muted"></i></span></div>
                  </div>
                </div>
    
                
                     
                <div className="col-sm-6 col-xl-4">
                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover" style={{height: '200px'}}><a className="img-overlay" href="real-estate-single-v1.html h-100" style={{height: '200px'}}></a>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"><span className="d-table badge bg-success mb-1">Verified</span></div>
                      <div className="content-overlay end-0 top-0 pt-3 pe-3">
                        <button className="btn btn-icon btn-light btn-xs text-primary rounded-circle" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to Wishlist"><i className="fi-heart"></i></button>
                      </div>
                      <Slider className='slider3 h-100' {...settings}><img src={image8} alt="Image"  style={{height: '100%', objectFit: 'cover', width: '100%'}}/><img src={image8} alt="Image"  style={{height: '200px', objectFit: 'cover', width: '100%'}}/>
                                            </Slider>
                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary">For rent</h4>
                      <h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" href="real-estate-single-v1.html">3-bed Apartment | 67 sq.m</a></h3>
                      <p className="mb-2 fs-sm text-muted">3811 Ditmars Blvd Astoria, NY 11105</p>
                      <div className="fw-bold"><i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>$1,629</div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"><span className="d-inline-block mx-1 px-2 fs-sm">3<i className="fi-bed ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block mx-1 px-2 fs-sm">2<i className="fi-bath ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block mx-1 px-2 fs-sm">2<i className="fi-car ms-1 mt-n1 fs-lg text-muted"></i></span></div>
                  </div>
                </div>
                
                <nav class="border-top pb-md-4 pt-4 mt-2" aria-label="Pagination">
                  <ul class="pagination mb-1">
                    <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
                    <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="visually-hidden">(current)</span></span></li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
                    <li class="page-item d-none d-sm-block">...</li>
                    <li class="page-item d-none d-sm-block"><a class="page-link" href="#">8</a></li>
                    <li class="page-item"><a class="page-link" href="#" aria-label="Next"><i class="fi-chevron-right"></i></a></li>
                  </ul>
                </nav>
              </div>
    </div>
    </div>
    </div>
  )
}

export default TopLuxury