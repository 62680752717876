import logo from './logo.svg';
import './App.css';
import './asserts/css/theme.min.css';
import Accra_home from './pages/accra_home';
import Footer from './components/footer';
import Navbar from './components/navbar';
import FindAccomodation from './pages/findAccomodation'
import SignUppage from './pages/signupPage'
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Layout from './pages/Layout';
import SigninPage from './pages/signinPage';
import OtpPage from "./pages/otpPage"
import RequireAuth from './components/requireAuth';
import ProfilePage from './pages/profilePages/profileInfoPage';
import RequireOtp from './components/requireOtp';
import RecentBooking from './pages/profilePages/recentBooking';
import TopLuxury from './pages/topLuxury';
import PendingBooking from './pages/profilePages/pendingBooking';
import PasswordSecurity from './pages/profilePages/passwordSecurity';
import MyProperties from './pages/profilePages/myProperties';
import Wishlist from './pages/profilePages/wishlist';
import RefundRequest from './pages/profilePages/refundRequest';
import Reviews from './pages/profilePages/reviews';
import Notifications from './pages/profilePages/notifications';
import HelpCenter from './pages/helpCenter';
import AddProperty from './pages/property/addProperty';
import PropertyDetailPage from './pages/property/propertyDetailPage';
import MyPropertyDetail from './pages/profilePages/myPropertyDetail';
import EditProperty from './pages/property/editProperty';
import Events from './pages/events';
import EventDetail from './pages/eventDetail';
import Blog from './pages/blog';
import FAQ from './pages/FAQ';
import Contact from './pages/contact';
import Backtotop from './components/backtotop';
import PrivacyPolicy from './pages/privacyPolicy';
import Termsandconditions from './pages/termsandconditions';
import DashboardLayout from './components/dashboardLayout';
import BlogDetail from "./pages/blogDetail"
import PaymentHistoryHost from './pages/transaction/paymentHistoryHost';
import PayoutForm from './pages/transaction/payoutForm';
import PayOutHistory from './pages/profilePages/payOutHistory';
import ScrollToTop from './components/scrollToTop';
import PropertyBookingPaymentDetail from './pages/property/propertyBookingDetail';

function App() {

 

  return (
    
    <div className="page-wrapper">
   
<Router>
<ScrollToTop/>
    <Routes>
    
      <Route path="/register" element={<SignUppage />} />
      <Route path="/login" element={<SigninPage />} />
      <Route path="/verify" element={<OtpPage />} />
      <Route element={<RequireOtp />}>
        <Route path="/" element={<Layout><Accra_home /></Layout>} />
        <Route path="properties/luxury" element={<Layout><TopLuxury /></Layout>} />
        <Route path="/properties" element={<Layout><FindAccomodation /></Layout>} />
        <Route path="/experiences" element={<Layout><Events /></Layout>} />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard/" element={<DashboardLayout />}>
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="password-security" element={<PasswordSecurity />} />
            <Route path="personal-info" element={<ProfilePage />} />
            <Route path="recent-booking" element={<RecentBooking />} />
            <Route path="my-properties/approvals" element={<PendingBooking />} />
            <Route path="my-properties" element={<MyProperties />} />
            <Route path="refund-request" element={<RefundRequest />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="my-properties/edit/:id" element={<EditProperty />} />
            <Route path="payment-history" element={<PaymentHistoryHost />} />  
            <Route path="payout-history" element={<PayOutHistory />} />  

                   

                      </Route>
                      <Route path="payment-detail/:id" element={<Layout><PropertyBookingPaymentDetail/></Layout> } />  

                      <Route path="my-properties/:id" element={<Layout><MyPropertyDetail /></Layout>} />
            <Route path="my-properties/edit/:id" element={<Layout><EditProperty /></Layout> } /> 
            <Route path="/dashboard/my-properties/add" element={<Layout><AddProperty /></Layout>} /> 
                      <Route path="/dashboard/payout-form" element={<Layout><PayoutForm /></Layout>} /> 

        </Route>
       


        <Route path="/help" element={<Layout><HelpCenter /></Layout>} />
      </Route>
     
      <Route path="/properties/detail/:id" element={<Layout><PropertyDetailPage /></Layout>} />
      <Route path="/events/detail/:id" element={<Layout><EventDetail /></Layout>} />

      <Route path="/posts" element={<Layout><Blog /></Layout>} />
      <Route path="/posts/detail/:id" element={<Layout><BlogDetail/></Layout>} />

      <Route path="/FAQs" element={<Layout><FAQ /></Layout>} />
      <Route path="/contact" element={<Layout><Contact /></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
      <Route path="/terms-of-service" element={<Layout><Termsandconditions /></Layout>} />
    </Routes>
  </Router>
      <Backtotop/>
    </div>

    
   
   
  );
}

export default App;
