import React, { useEffect, useState } from 'react'
import PageLoading from '../components/landingcomponents/pageLoader';
import { Link } from 'react-router-dom';
import FAQComponent from '../components/FAQComponent';
import { useGetFAQMutation } from '../features/property/FAQSlice';
import Skeleton from 'react-loading-skeleton';
function FAQ() {


    const [getfaq,{isLoading}] = useGetFAQMutation();
    const [faqs,setFAQs]=useState()
    const handlegetfaq=async()=>{

        const response= await getfaq()
        console.log(response)
        setFAQs(response.data)

    }

    useEffect(()=>{
        handlegetfaq()

    },[])
  return (
    <div className="container-fluid mt-5 pt-5 p-0">
    <section class="bg-dark py-5">
        <div class="container pt-5 pb-5">
         
          <nav class="mb-4 pb-lg-3" aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light">
              <li class="breadcrumb-item"><Link to='/' href="job-board-home-v1.html">Home</Link></li>
        
              <li class="breadcrumb-item active" aria-current="page">FAQ's</li>
            </ol>
          </nav>
      
          <div class="mb-lg-5 mx-auto text-center" style={{maxWidth:"856px"}}>
            <h1 class="text-light mb-4 pb-3">Frequently Asked Questions</h1>
         
          </div>
        </div>
      </section>

      <div class="col-8 offset-lg-1 py-5" style={{marginBottom:"60px"}}>
              <div class="mb-md-5 mb-4 pb-lg-2" id="popular-questions" tabindex="-1" style={{outline: 'none'}}>
                <h2 class="h4 mb-md-4">Popular questions</h2>
                
                <div class="accordion" id="accordionPQ">
                {
                    isLoading?
                    <>
                    <Skeleton style={{width:"100%",height:"60px",marginBottom:"10px"}}/>
                    <Skeleton style={{width:"100%",height:"60px",marginBottom:"10px"}}/>
                    <Skeleton style={{width:"100%",height:"60px",marginBottom:"10px"}}/>
                    </>
                    :
                    faqs&& faqs.map((item,index)=>{

                        return (
                            <FAQComponent key={index}  question={item.question} answer={item.answer} />

                        )

                    })

                    
                }
                
              
                </div>
              </div>
              
           
              
            
            </div>
      <PageLoading />
      </div>
  )
}

export default FAQ