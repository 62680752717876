import React ,{useState} from 'react'
import signinlogo from "../asserts/img/signin-modal/signin.svg"
import { Link, useNavigate } from 'react-router-dom';
import { useVerifynumberMutation,useResendotpMutation } from '../features/auth/authApiSlice';
import { useSelector,useDispatch } from 'react-redux';
import { selectCurrentUser } from '../features/auth/authSlice';
import { logOut } from '../features/auth/authSlice';
import { setCredentials } from '../features/auth/authSlice';
import { store } from '../app/store';


function OtpPage() {

const  [seepassword,setseepassword]=useState(false)
const navigate = useNavigate();
const [verifynumber,{isLoading:isLoading}]=useVerifynumberMutation()
const[resend,{isLoading:isSending}]=useResendotpMutation()
const user_id =useSelector(selectCurrentUser)
const [resendsent,setResendsent]=useState(false)
const [otpverified,setOtpVerfied]=useState(false)
const [wrongopt,setWrongotp]=useState(false)
const dispatch=useDispatch()

const [formData, setFormData] = useState({

  'otp':null,
  'user_id':null
    

});

const handleLogout=()=>{
  dispatch(logOut())
  navigate("/")
  

}

const handleAlertClose1=()=>{
  setResendsent(false)
}

const handleAlertClose2=()=>{
  setOtpVerfied(false)
}

const handleAlertClose4=()=>{
  setWrongotp(false)
}


 const goBack = () => {
   navigate(-1);
 

 }




 const handleOtp=async (e)=>{
  e.preventDefault()
  
    
  
        try {
  
  
  console.log(user_id)
  console.log(formData)
  formData.user_id=user_id
  console.log(formData)
          const verifyData= await verifynumber({otp:parseInt(formData.otp),user_id:user_id}).unwrap()
          console.log(verifyData)

          if (verifyData.status=="success"){
            const currentState = store.getState().auth;
            dispatch(setCredentials({
              access:currentState.access , // new value for access token
              refresh: currentState.refresh, // current refresh token
              is_number_verified:"true", // current verification status
              user_id: currentState.user_id // current user ID
            }));

            setOtpVerfied(true)
            formData.user_id=""
            formData.otp=""
            navigate('/dashboard/personal-info')
            
          }
          
       
        }
          
            catch(e){
              console.log(e)
              if (e.status==401){
                setWrongotp(true)
              }

            }
        
      }
    

const handleResendOtp=async()=>{

  try {
  
  
    console.log(user_id)
  
    const resendData= await resend({"user_id":user_id}).unwrap()
    console.log(resendData.status)
    if (resendData.status=="success"){
      setResendsent(true)
      formData.user_id=""
      formData.otp=""

      
    }
    
  
 
  }
    
      catch(e){
        console.log(e)

      }

}
  

 
 const handleChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
};


  return (
    <div className=" fade show fade py-5" style={{diaplay:"block",backgroundColor:"#f0f0f0"}} id="signup-modal" tabindex="-1" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered p-2 my-0 mx-auto" style={{maxWidth: "950px"}}>
      <div className="modal-content">
        <div className="modal-body px-0 py-2 py-sm-0">
        <buton  onClick={goBack} style={{cursor:"pointer"}} class=" position-absolute top-0 end-0 nav-link fs-sm py-1 px-2 mt-3 me-3" href="#" onclick="window.history.go(-1); return false;">
				<i class="fi-arrow-long-left fs-base me-2"></i>
				Go back
			</buton>          <div className="row mx-0 align-items-center">
            <div className="col-md-6 border-end-md p-4 p-sm-5">
              <h2 className="h3 mb-4 mb-sm-5">
              Hey there!<br/>
Verify Your Email Address
              </h2>
              <img className="d-block mx-auto" src={signinlogo} width="344" alt="Illustartion"/>
              <div className="mt-sm-4 pt-md-3 d-flex justify-content-center align-items-center">Don't want to verify now? <a onClick={handleLogout}  className='nav-link' style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-dismiss="modal">Logout Here</a></div>
            </div>
            <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
            <p>Before proceeding, please check your email for an OTP Code.</p>


      <form onSubmit={handleOtp} className="needs-validation" novalidate>
  
  {
    resendsent?<div class="alert alert-success alert-dismissible fade show" role="alert">
  <span class="fw-bold">Success!</span> 0TP resent successfully
  <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={handleAlertClose1}></button>
</div>:""

  }

  {
    wrongopt?<div class="alert alert-danger alert-dismissible fade show" role="alert">
  <span class="fw-bold">Error!</span> Wrong OTP Code 
  <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={handleAlertClose4}></button>
</div>:""

  }
  {
    otpverified?<div class="alert alert-success alert-dismissible fade show" role="alert">
  <span class="fw-bold">Success!</span> 0TP resent successfully
  <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={handleAlertClose2}></button>
</div>:""

  }


         

                <div className="mb-4">
                  <label className="form-label text-muted" for="signup-email">OTP Code</label>
                  <input className="form-control" name='otp' onChange={handleChange} type="number" id="" placeholder="OTP Code here"  required/>
                </div>


                {
  isSending ? (
    <button type="button" className="btn btn-primary btn-lg rounded-m w-100">
      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      resending ...
    </button>
  ) : isLoading ? (
    <button type="button" className="btn btn-primary btn-lg rounded-m w-100">
      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      Verifying ...
    </button>
  ) : (
    <button className="btn btn-primary btn-lg rounded-m w-100 mb-4" type="submit">Verify & Proceed</button>
  )
}


          

              </form>
              <p>If you did not receive a code </p>
                <div class="col-12">
								<button type="submit" onClick={handleResendOtp}  class="btn btn-link p-0 m-0 align-baseline">
									Click here to request another
								</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )

  
}

export default OtpPage