
import { useRef,React,useEffect ,useState} from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetAllPropertiesMutation ,} from '../../features/property/propertySlice'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PropertyCard from '../propertyCard'
import { Link } from 'react-router-dom'

function AvailableListing() {
  const [getproperties,{isLoading}]=useGetAllPropertiesMutation()
  const [properties,setProperties]=useState()

  const handleGetProperties=async()=>{
    try {
      const response = await getproperties()
      console.log(response.data.results)
      setProperties(response.data.results)
   
    }catch(e){
      console.log(e)
      
    }
   
  }

 

  useEffect(()=>{
    handleGetProperties()
  },[])
    const sliderRef = useRef();

    useEffect(() => {
        // Ensure the ref is set and the component has mounted
        if (sliderRef.current) {
            const slickTrack = sliderRef.current.querySelector('.slick-track');
            const slickList= sliderRef.current.querySelector('.slick-list');
            if (slickList){
         


            }

            if (slickTrack) {
                slickTrack.classList.add('row' ,'gx-4','mx-0', 'pt-3', 'pb-4');
               
            }
        }
    }, []);


    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipe:true,
        touchMove:true,
        rows:1,
       
      
      
    
        responsive: [
            {
                breakpoint: 991.98, // At max-width of 1024px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                    swipe:true,
                    touchMove:true,
                    speed: 500,
                    rows:1,
                    


                }
            },
            {
                breakpoint: 767.98, // At max-width of 768px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll:1,
                    swipe:true,
                    touchMove:true,
                    speed: 500,
                    dots: true,
                    infinite: false,
                    rows:1,

                   
                }
            },
            {
                breakpoint: 499.98, // At max-width of 480px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipe:true,
                    touchMove:true,
                    speed: 500,
                    infinite: false,
                    dots: true,

                }
            }
        ]
    };

   


      
      
  return (
    <section className="container mb-5 pb-md-4">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h2 className="h3 mb-0">Top offers</h2><Link to='/properties' className="btn btn-link fw-normal p-0" href="real-estate-catalog-rent.html">View all<i className="fi-arrow-long-right ms-2"></i></Link>
        </div>
        <div className='row gy-4'>

        {
                    isLoading?
                    <>
                                        <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
            
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            <div className="col col-md-6 col-lg-3  col-sm-6 ">
              <div  className="card shadow-sm card-hover border-0 h-100">
                <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                  <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>
                  
                  
                  <Skeleton style={{width:"400px",height:"200px"}}/>

                </div>
                <div className="card-body position-relative pb-3">
                  <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{width:"80px",height:"7px"}}/></h4>
                  <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{width:"150px",height:"15px"}}/></div></h3>
                  <p className="mb-2 fs-sm text-muted"><Skeleton style={{width:"100px",height:"10px"}}/></p>
                  <div className="fw-bold"><Skeleton style={{width:"100px",height:"20px"}}/></div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
              </div>
            </div>
           
           
            </>

                    :
                    

                    <>

                  

{
  properties&& properties.slice(0,12).map((property,index)=>(<div key={index} className="col-md-6 col-lg-3  col-sm-6 ">
             <PropertyCard property={property} />
            </div>)
  )
}

</>

      }

         


</div>
      </section>
      
  )
}

export default AvailableListing