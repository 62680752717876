import React ,{useRef, useState}from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../components/profilecomponents/sidebar'
import { useChangepasswordMutation } from '../../features/auth/authApiSlice'

function PasswordSecurity() {
const[currentPasswordVisible,setCurrentPasswordVisible]=useState(false)
const[newPasswordVisible,setNewPasswordVisible]=useState(false)
const[confirmPasswordVisible,setConfirmPasswordVisible]=useState(false)

const [newPasswordData,setPasswordData]=useState({
  "old_password":"",
  "new_password":"",
})

const [confirmpassword,setConfirmPassword]=useState("")
const [updatepassword,{isLoading}]=useChangepasswordMutation()
const formref=useRef()
const newpassworddanger=useRef()
const oldpassworddanger=useRef()
const confirmpassworddanger=useRef()
const [success,setSuccess]=useState(false)



const handlCurrentPassword=()=>{
    setCurrentPasswordVisible(!currentPasswordVisible)
}


const handleChange = (event) => {
  const { name, value } = event.target;
  setPasswordData((prevFormData) => ({ ...prevFormData, [name]: value }));
};




const handleChange1 = (e) => {
  setConfirmPassword(e.target.value);
};



const handleSubmit=async(e)=>{
  e.preventDefault();
  const isValid = formref.current.checkValidity(); // Check if all form elements are valid
  

  if (isValid){
    if (newPasswordData.new_password==confirmpassword){
      try{
        const  passwordData= await updatepassword(newPasswordData).unwrap()
         console.log(passwordData)
         if (passwordData.message=="password updated successfully"){
            setSuccess(true)
         }
       }catch(e){
     console.log(e)
     if (e.data.message==="Wrong old password." ){
      oldpassworddanger.current.innerText=e.data.message
     }
       }
    }
    else{
      confirmpassworddanger.current.innerText="Password Does Not Match!"


    }
    

  }else {
		formref.current.classList.add("was-validated")
	    }
 
 
}


  return (


 
  <div className="col-lg-8 col-md-7 mb-5">
            <h1 className="h2">Password &amp; Security</h1>
            <p className="pt-1">Manage your password settings and secure your account.</p>
            <h2 className="h5">Password</h2>
{
  success?         <div class="alert alert-success alert-dismissible fade show" role="alert">
  <span class="fw-bold"></span> Password Updated Successfully
  <button  onClick={()=>{setSuccess(false)}} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:""
}
   
            <form onSubmit={handleSubmit} className="needs-validation pb-4 " ref={formref} novalidate>
   


              <div className="row align-items-end mb-2">
                <div className="col-sm-6 mb-2">
                  <label className="form-label" for="account-password">Current password</label>
                  <div className="password-toggle">
                    <input className="form-control" onFocus={()=>{oldpassworddanger.current.innerText=""}}  pattern=".*[\W_].*" minLength='8'  name='old_password' value={newPasswordData.old_password} onChange={handleChange}  type={currentPasswordVisible?"text":"password"}   required/>
                    <label onClick={handlCurrentPassword}  className="password-toggle-btn" aria-label="Show/hide password">
  <i  className={ currentPasswordVisible?"fi-eye-off":"fi-eye-on"  }></i>
                    </label>
                  </div>

                  <span class="text-danger fs-xs" ref={oldpassworddanger}></span>

                </div>
                <div className="col-sm-6 mb-2"><a className="d-inline-block mb-2" href="#">Forgot password?</a></div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-6 mb-3">
                  <label className="form-label" for="account-password-new">New password</label>
                  <div className="password-toggle">
                    <input className="form-control"   pattern=".*[\W_].*" minLength='8' id="account-password-new" name='new_password' value={newPasswordData.new_password} onChange={handleChange}  type={newPasswordVisible?"text":"password"}  required/>
                    <label onClick={()=>{setNewPasswordVisible(!newPasswordVisible)}}  className="password-toggle-btn" aria-label="Show/hide password">
  <i  className={ newPasswordVisible?"fi-eye-off":"fi-eye-on"  }></i>
                    </label>
                  </div>

                  <span class="text-danger  fs-xs" ref={newpassworddanger}></span>

                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label" for="account-password-confirm">Confirm password</label>
                  <div className="password-toggle">
                    <input onFocus={()=>{confirmpassworddanger.current.innerText=""}}  pattern=".*[\W_].*" minLength='8' className="form-control" name='confirm_password' onChange={handleChange1} type={confirmPasswordVisible?"text":"password"}   id="account-password-confirm" required/>
                    <label onClick={()=>{setConfirmPasswordVisible(!confirmPasswordVisible)}}  className="password-toggle-btn" aria-label="Show/hide password">
  <i  className={ confirmPasswordVisible?"fi-eye-off":"fi-eye-on"  }></i>
                    </label>
                  </div>
                  <span class="text-danger  fs-xs" ref={confirmpassworddanger}></span>

                </div>
                <span className='fs-xs text-muted ' >min. 8 characters & at least a special character</span>

              </div>


              {
					isLoading?<button type="button" className="btn btn-outline-primary">
  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
   updating ...
  
</button>: <button className="btn btn-outline-primary" type='submit' >update Password</button>
              }

            </form>
            <div className="border-top pt-4 mt-3"></div>
            <h2 className="h5 pt-2 mb-4">Where you're signed in on</h2>
            <div className="d-flex border-bottom pb-3 mb-3"><i className="fi-device-desktop fs-5 text-muted me-1"></i>
              <div className="ps-2">
                <div className="fw-bold mb-1">Mac – New York, USA</div><span className="d-inline-block fs-sm text-muted border-end pe-2 me-2">Chrome</span><span className="fs-sm fw-bold text-success">Active now</span>
              </div>
            </div>
            <div className="d-flex border-bottom pb-3 mb-3"><i className="fi-device-mobile fs-5 text-muted me-1"></i>
              <div className="ps-2">
                <div className="fw-bold mb-1">iPhone 12 – New York, USA</div><span className="d-inline-block fs-sm text-muted border-end pe-2 me-2">Finder App</span><span className="fs-sm text-muted">20 hours ago</span>
              </div>
              <div className="align-self-center ms-auto">
                <div className="dropdown">
                  <button className="btn btn-icon btn-light btn-xs rounded-circle shadow-sm" type="button" id="contextMenu1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fi-dots-vertical"></i></button>
                  <ul className="dropdown-menu pb-3 my-1" aria-labelledby="contextMenu1">
                    <li><a className="dropdown-item text-body" href="#">Not you?</a></li>
                    <li><a className="d-block px-3" href="#">Sign out</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex border-bottom pb-3 mb-3"><i className="fi-device-desktop fs-5 text-muted me-1"></i>
              <div className="ps-2">
                <div className="fw-bold mb-1">Windows 10.1 – New York, USA</div><span className="d-inline-block fs-sm text-muted border-end pe-2 me-2">Chrome</span><span className="fs-sm text-muted">November 15 at 8:42 AM</span>
              </div>
              <div className="align-self-center ms-auto">
                <div className="dropdown">
                  <button className="btn btn-icon btn-light btn-xs rounded-circle shadow-sm" type="button" id="contextMenu2" data-bs-toggle="dropdown" aria-expanded="false"><i className="fi-dots-vertical"></i></button>
                  <ul className="dropdown-menu pb-3 my-1" aria-labelledby="contextMenu2">
                    <li><a className="dropdown-item text-body" href="#">Not you?</a></li>
                    <li><a className="d-block px-3" href="#">Sign out</a></li>
                  </ul>
                </div>
              </div>
            </div><a className="d-inline-block fw-bold text-decoration-none mt-3" href="#">Sign out of all sessions     </a>
          </div>

  
  )
}

export default PasswordSecurity