import React,{ useState, useRef } from 'react'
import signinlogo from "../../asserts/img/signin-modal/signin.svg"
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation ,useLogiWithGoogleMutation} from '../../features/auth/authApiSlice';
import { useGoogleLogin} from '@react-oauth/google';


function SigninModal({ show ,backdrop,changeshow,changebackdrop}) {
  
  const className = show ? 'show' : '';
  const display = show ? 'block' : '';
 
 
  const  [seepassword,setseepassword]=useState(false)
  const navigate = useNavigate();
  const formref=useRef(null)
  const[login,{isLoading}]=useLoginMutation()
  const dispatch = useDispatch()
  const[incorrectdetails,setIncorectDetails]=useState(false)
  const [loginwithGoogle,{isLoading:isLoadingGoogle}]=useLogiWithGoogleMutation()
  const [googleError,setGoogleError]=useState()

  
  const handleAlertClose=()=>{
    setIncorectDetails(false)
  }
  
  const [formData, setFormData] = useState({
   
    email: "",
    'password':"",
    
    
  
  });
  

  
   const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  
  
  const loginUsingGoogle = useGoogleLogin({
    onSuccess: async(codeResponse) => {

      const signinData = await loginwithGoogle({ access_token: codeResponse.access_token }).unwrap();
      dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh}))
      window.location.reload()



      
    },
    onError: (error) => console.log('Login Failed:', error)
});

  
  const handleLoginSuccess = async (response) => {
    try {
      console.log(response)

     
        const signinData = await loginwithGoogle({ access_token: response.credential }).unwrap();
        console.log("Signin Data:", signinData);

        // Handle email not found error or other specific errors
        if (signinData.error ) {
            console.error("Email-related error:", signinData.error.message);
            setGoogleError(signinData.error.message)
            // Optionally, display an error message to the user
        } else {
            // Proceed with your existing logic
            if (signinData.is_number_verified === false) {
                dispatch(setCredentials({ access: signinData.access, refresh: signinData.refresh, user_id: signinData.user_id, is_number_verified: signinData.is_number_verified }));
                navigate("/verify");
            } else {
                dispatch(setCredentials({ access: signinData.access, refresh: signinData.refresh, user_id: signinData.user_id, is_number_verified: signinData.is_number_verified }));
                navigate("/");
            }
        }

        // Clear form data
        formData.email = "";
        formData.password = "";

    } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
    }
};


  const handleLoginFailure = (response) => {
    console.error("Login Failed: ", response);
  };
  
  const handleLogin=async (e)=>{
  e.preventDefault()
      const isValid = formref.current.checkValidity(); // Check if all form elements are valid
  
    console.log(isValid);
    
    
  
      if (isValid) {
  
        try {
  
  
  
          const signinData= await login({"email":formData.email,'password':formData.password}).unwrap()
          console.log(signinData)
          dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_number_verified:signinData.is_number_verified}))
          if (signinData.is_number_verified===false){
            navigate("/verify")
          }
          else{
            changeshow()
            window.location.reload()
          }
        
        formData.email=""
        formData.password=""
        }
          
            catch(e){
              console.log(e)
              if ( e.status===401){
  setIncorectDetails(true)
              }
            }
        
      }
      else{
  
        formref.current.classList.add("was-validated")
  
  
      }
  
  }
  


  return (
    backdrop && (
      <div className={`backdrop ${show ? 'show' : 'fade'}`} >
    <div className={`modal ${show ? 'open-modal' : ''} `} id="signin-modal" tabindex="-1" style={{paddingLeft: '0px'}} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered p-2 my-0 mx-auto" style={{maxwidth: '950px'}}>
          <div className="modal-content">
            <div className="modal-body px-0 py-2 py-sm-0">
              <button onClick={changeshow} className="btn-close position-absolute top-0 end-0 mt-3 me-3" type="button" data-bs-dismiss="modal"></button>
              <div className="row mx-0 align-items-center">
                <div className="col-md-6 border-end-md p-4 p-sm-5">
                  <h2 className="h3 mb-4 mb-sm-5">Hey there!<br/>Welcome back.</h2><img className="d-block mx-auto" src={signinlogo} width="344" alt="Illustartion"/>
                  <div className="mt-4 mt-sm-5">Don't have an account? <Link  to='/register' data-bs-toggle="modal" data-bs-dismiss="modal">Sign up here</Link></div>
                </div>
                <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">

                <div className="mb-4">


</div>                
                

                  <div className="d-flex align-items-center py-3 mb-3">
                    <hr className="w-100"/>
                    <div className="px-3">Or</div>
                    <hr className="w-100"/>
                  </div>
                  <form onSubmit={handleLogin} className="needs-validation" ref={formref} noValidate>

                  <button  type='button' onClick={loginUsingGoogle} class="btn btn-outline-info rounded-pill  w-100 mb-3  " href="#"> 
              <svg aria-hidden="true" class="native svg-icon iconGoogle mb-1" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.8 4.8 0 0 1 4.48-3.3"></path></svg>
               <span className="mx-1">Sign in with Google</span> </button>
{
incorrectdetails?             <div class="alert alert-danger alert-dismissible fade show" role="alert">
<span class="fw-bold">Error</span> Invalid username or password!
<button type="button" class="btn-close" onClick={handleAlertClose} ></button>
</div>:""
}


{
googleError?             <div class="alert alert-danger alert-dismissible fade show" role="alert">
<span class="fw-bold">Error</span> googleError
<button type="button" class="btn-close" onClick={()=>{setGoogleError()}} ></button>
</div>:""
}


            <div className="mb-4">
              <label className="form-label" for="signup-email">Email address</label>
              <input  name="email"   onChange={handleChange}  value={formData.email} className="form-control" type="email" id="signin-email" placeholder="Enter your email" required/>
            </div>
           

            <div className="mb-4">
            <div class="d-flex align-items-center justify-content-between mb-2">
            <label class="form-label mb-0" for="signin-password">Password</label>
            <a class="fs-sm" href="">
              Forgot password?
            </a>
          </div>                  <div className="password-toggle">
                <input  name="password"   onChange={handleChange}  value={formData.password}  className="form-control" type={seepassword ? "text":"password"} id="signup-password" minlength="8" required/>
                <label className="password-toggle-btn" aria-label="Show/hide password">
                  <input className="password-toggle-check" type="checkbox"/>
                  <span onClick={()=>{ setseepassword(!seepassword)}}   className="password-toggle-indicator"></span>
                </label>
              </div>
            </div>

{
isLoading?<button type="button" class="btn btn-primary btn-lg mb-2  rounded-m w-100 ">
<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
Login in ...

</button>: <button className="btn btn-primary mb-2 btn-lg rounded-m w-100 " type="submit">Sign in</button>

}

          </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

</div>
    )
  );
}


export default SigninModal

