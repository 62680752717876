import React ,{useEffect, useState,useRef}from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { GoogleMap, LoadScript,  MarkerF,useJsApiLoader ,Autocomplete } from '@react-google-maps/api';
import { useGetCategoriesMutation,useGetAmenitiesMutation,useGetPrevilegeMutation,useGetRegionsMutation} from '../../features/property/propertySlice';
import { useAddPropertyMutation } from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Collapse }from 'reactstrap'


import marker from '../../asserts/img/map/marker.png'
import { MultiSelect } from "react-multi-select-component";
import image from '../../asserts/img/city-guide/about/01.jpg'

import map from '../../asserts/img/real-estate/single/map.jpg'


import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import {
	setKey,	
	setLanguage,
	fromLatLng,
  } from "react-geocode"
// Register the plugins
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);



  const placesLibrary = ['places']
  

function AddProperty() {
	const [getcategories,{isLoading}]=useGetCategoriesMutation()
	const [getamenities,{isLoading:isLoadingAmenities}]=useGetAmenitiesMutation()
	const [getprevileges,{isLoading:isLoadingPrevileges}]=useGetPrevilegeMutation()
	const [getregions,{isLoading:isLoadingRegions}]=useGetRegionsMutation()
	const [addproperty,{isLoading:isLoadingAddingProperty}]=useAddPropertyMutation()
	const navigate=useNavigate()
	const imageref=useRef()
	const imageref2=useRef()

	const longref=useRef(null)
	const latref=useRef(null)
	const addressref=useRef(null)
	const cityref=useRef(null)
	const addressrefvalue=useRef(null)
	const cityrefvalue=useRef(null)
	const maplinkref=useRef(null)
	const regionref=useRef(null)
	const typref=useRef(null)
	const [categories,setCategories]=useState()
	const [propertyData,setPropertyData]=useState()
	const [regionData,setRegionData]=useState()
	const [amenities,setAmenities]=useState()
	const [previleges,setPrevileges]=useState()
	const [markerPosition, setMarkerPosition] = useState(null);
	const [options,setOptions]=useState([]);
	const autocompleteRef = useRef(null);
	const [percentagelevel, setPercentageLevel] = useState()
	const [validated, setValidated] = useState(false);

	const [singleImages,setSingleImages]=useState([])
	const [multipleImages,setMultipleImages]=useState([])

	const [basicInfoPercentage, setBasicInfoPercentage] = useState(0);
	const [locationPercentage,setLocationPercentage] = useState(0)
	const [propertyDetailPercentage,setPropertyDetailPercentage] = useState(0)
	const [pricePercentage,setPricePercentage] = useState(0)
	const [imagePercentage,setImagePercentage] = useState(0)
	const [isServiceChargeOpen, setIsServiceChargeOpen] = useState(false);





	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg",
		libraries: placesLibrary,
		language:"en",
		region:"GH",
	  })

	
	
  // Set the API key for react-geocode
  setKey("AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg");

  // Set response language. Defaults to english.
  setLanguage("en");

  // Optionally set region
  
  // Enable or disable logs. Its optional.



  const getAddress = () => {
    fromLatLng(latref.current.value, longref.current.value).then(
      response => {
        const address = response.results[0].formatted_address;
        const city = response.results[0].address_components.find(component => component.types.includes("locality"))?.long_name;
        const region = response.results[0].address_components.find(component => component.types.includes("administrative_area_level_1"))?.long_name;
		addressrefvalue.current.value = address
		properdetail.address=address
		cityrefvalue.current.value =city
		regionref.current.value =region
		

		maplinkref.current.value = `https://www.google.com/maps/search/?api=1&query=${latref.current.value},${longref.current.value}`
		console.log(address)
		console.log(city)
		console.log(region)
		
       

		
			
		
			  setPropertyDetail(prevFormData => ({ ...prevFormData, "map_link": `https://www.google.com/maps/search/?api=1&query=${latref.current.value},${longref.current.value}` }));
			
			
		
			  setPropertyDetail(prevFormData => ({ ...prevFormData, "address": address }));
			  setPropertyDetail(prevFormData => ({ ...prevFormData, "longitude": longref.current.value }));
			  setPropertyDetail(prevFormData => ({ ...prevFormData, "latitude": latref.current.value }));




		
      },
      error => {
        console.error(error);
      }
    );
  };



	const 	handleClickMap=(e)=>{
		
		
		const latlng=e.latLng.toJSON()
		latref.current.value = latlng.lat
		longref.current.value = latlng.lng
		setMarkerPosition(e.latLng.toJSON());

		getAddress()
	}


	const handlePlaceSelected = () => {
		if (cityref.current) {
			const place = cityref.current.getPlace();
			console.log('Selected Place:', place);
	
			const address = place.formatted_address;
			const latitude = place.geometry.location.lat();
			const longitude = place.geometry.location.lng();
			const map_link = place.url;
	
			addressrefvalue.current.value = address;
			properdetail.address = address;
			latref.current.value = latitude;
			longref.current.value = longitude;
			maplinkref.current.value = map_link;
			properdetail.map_link = map_link;
			
	
			setMarkerPosition({
				lat: latitude,
				lng: longitude
			});
	
			let region = '';
			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					region = component.long_name;
					regionref.current.value=region
				}
			});
	
			setPropertyDetail(prevFormData => ({ ...prevFormData, "map_link": map_link }));
			setPropertyDetail(prevFormData => ({ ...prevFormData, "address": address }));
			setPropertyDetail(prevFormData => ({ ...prevFormData, "longitude": longitude }));
			setPropertyDetail(prevFormData => ({ ...prevFormData, "latitude": latitude }));

	
			console.log('Region:', region);
		}
	};

	
	  function extractLatLngFromMapLink(mapLink) {
		const regex = /@([\-?\d\.]*),([\-?\d\.]*)/;
		const match = mapLink.match(regex);
		return match ? { lat: parseFloat(match[1]), lng: parseFloat(match[2]) } : null;
	  }
	
	
	  const parseCoordinates = () => {
		const extractLatLngFromMapLink = (url) => {
			try {
				const urlParams = new URLSearchParams(new URL(url).search);
				const query = urlParams.get('query');
				if (query) {
					const [lat, lng] = query.split(',').map(Number);
					return { lat, lng };
				}
			} catch (error) {
				console.error('Error parsing URL:', error);
			}
			return null;
		};
	
		const coords = extractLatLngFromMapLink(maplinkref.current.value);
		if (coords) {
			console.log(coords);
	
			setMarkerPosition(coords);
			latref.current.value = coords.lat;
			longref.current.value = coords.lng;
	
			setPropertyDetail(prevFormData => ({
				...prevFormData,
				"longitude": coords.lng,
				"latitude": coords.lat
			}));
	
			fromLatLng(coords.lat, coords.lng).then(
				response => {
					const address = response.results[0].formatted_address;
					const city = response.results[0].address_components.find(component => component.types.includes("locality"))?.long_name;
	
					cityrefvalue.current.value = city;
					addressrefvalue.current.value = address;
	
					let region = '';
					response.results[0].address_components.forEach(component => {
						if (component.types.includes('administrative_area_level_1')) {
							region = component.long_name;
							regionref.current.value=region;
						}
					});
	
					setPropertyDetail(prevFormData => ({
						...prevFormData,
						"address": address,
						"map_link": maplinkref.current.value,
						
					}));
	
					console.log(address);
					console.log(city);
					console.log(region);
				},
				error => {
					console.error(error);
				}
			);

		}


		else{

			cityrefvalue.current.value = "";
					addressrefvalue.current.value = "";
	
					regionref.current.value=""
					

			setPropertyDetail(prevFormData => ({
				...prevFormData,
				"address": "",
				"map_link": maplinkref.current.value,
				
			}));

		}
	};
	


	  const handlePlaceSelected2 = () => {
		if (addressref.current) {
			const place = addressref.current.getPlace();
			console.log('Selected Place:', place);

			
			const city = place.vicinity;
			const latitude = place.geometry.location.lat();
			const longitude = place.geometry.location.lng();
			const map_link = place.url
			cityrefvalue.current.value=city
			latref.current.value=latitude
			longref.current.value=longitude
			maplinkref.current.value=map_link
			properdetail.map_link=map_link
			setMarkerPosition({
				lat:latitude,
				lng:longitude
			})

			let region = '';
			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					region = component.long_name;
					regionref.current.value=region;
				}
			});

			console.log('Region:', region);
			
			setPropertyDetail(prevFormData => ({ ...prevFormData, "map_link": map_link }));
			setPropertyDetail(prevFormData => ({ ...prevFormData, "address":addressrefvalue.current.value }));
			setPropertyDetail(prevFormData => ({ ...prevFormData, "longitude": longitude }));
			setPropertyDetail(prevFormData => ({ ...prevFormData, "latitude": latitude }))


		}	  



	  };
	

	  function convertOptions(data) {
		return data.map(item => ({
		  label: item.name,
		  value: item.id,
		  disabled: false // Assuming none are disabled; modify as necessary
		}));
	  }
	  
	  
	const handleGetCategories=async()=>{
		try{
	  
		  const categories= await getcategories()
		  setCategories(categories.data)
		  const newoptions=convertOptions(categories.data)
		  setOptions(newoptions)
		

	  
		}catch(e){
		  console.log(e)
	  
		}
	  }


	  const handleGetRegions=async()=>{
		try{
	  
		  const regions= await getregions()
		  
		  setRegionData(regions.data)
		  console.log(regions.data)
		  
	
		

	  
		}catch(e){
		  console.log(e)
	  
		}
	  }

	  const handleGetAmenities=async()=>{
		try{
	  
		  const amenities= await getamenities()
		  console.log(amenities)
		  
		  setAmenities(amenities.data)
	  
		}catch(e){
		  console.log(e)
	  
		}
	  }


	  const handleGetPrevileges=async()=>{
		try{
	  
		  const previleges= await getprevileges()
		  
		  setPrevileges(previleges.data)
	  
		}catch(e){
		  console.log(e)
	  
		}
	  }

	  useEffect(()=>{
		handleGetCategories()
		handleGetAmenities()
		handleGetPrevileges()
		handleGetRegions()
		calculateBasicPercentage()
	  },[])
	  

	const containerStyle = {
		width: '800px',
		height: '350px'
	  };
	  
	  const center = {
		lat: 5.5593,
		lng: 0.1974,
	  };



	    // Ref for the form
  const formRef = useRef(null);

  // Function to calculate the percentage
 


  const calculateBasicPercentage = () => {
    let filledCount = 0;
    const totalFields = 4; // Total number of required fields

    if (properdetail.title) filledCount++;
    if (selectedCategories.length > 0) filledCount++;
    if (typref.current.value !=="") filledCount++;
    if (properdetail.listing_property_as) filledCount++;

    
    const percentage = (filledCount / totalFields) * 100;
    const scaledPercentage = (percentage / 100) * 20;
    setBasicInfoPercentage(scaledPercentage)
	
  };


  const calculatePropertyDetailsPercentage = () => {
    let filledCount = 0;
    const totalFields = 7; // Total number of required fields
	if (properdetail.bathroom) filledCount++;
	if (properdetail.bedroom) filledCount++;
	if (properdetail.parking_spot) filledCount++;
    if (properdetail.amenity_ids.length > 0) filledCount++;
    if (properdetail.privilege_ids.length > 0) filledCount++;
    if (properdetail.is_party_inclusive.length > 0) filledCount++;
    if (properdetail.description) filledCount++;


	const percentage = (filledCount / totalFields) * 100;
    const scaledPercentage = (percentage / 100) * 20; // Scale to 20%
	setPropertyDetailPercentage(scaledPercentage.toFixed(1))
  };


  const calculateLocationPercentage = () => {
    let filledCount = 0;
    const totalFields = 6; // Total number of required fields including the new fields

    if (properdetail.longitude) {filledCount++
		 }
    if (properdetail.latitude) {filledCount++
		}
    if (properdetail.map_link) filledCount++;
    if  ( cityrefvalue.current && cityrefvalue.current.value !=="") filledCount++;
    if (properdetail.address) filledCount++;
    if (regionref.current&& regionref.current.value !=="" ) filledCount++;

    const percentage = (filledCount / totalFields) * 100;
    const scaledPercentage = (percentage / 100) * 20; // Scale to 20%
	setLocationPercentage(scaledPercentage.toFixed(1))
	
    
  };


  const calculatePricePercentage = () => {
    let filledCount = 0;
    const totalFields = 6; // Total number of required fields

	if (pricing.currency) filledCount++;
    if (pricing.discount_one_week) filledCount++;
    if (pricing.discount_one_month) filledCount++;
    if (pricing.discount_two_week) filledCount++;
	if (pricing.max_days_allowed) filledCount++;
	if (pricing.price) filledCount++;

	

    
    const percentage = (filledCount / totalFields) * 100;
    const scaledPercentage = (percentage / 100) * 20;
    setPricePercentage(scaledPercentage)

  };


  const calculateImagePercentage = () => {
    let filledCount = 0;
    const totalFields = 2; // Total number of required fields

	if (singleImages.length > 0) filledCount++; // Main image
	if (multipleImages.length > 0) filledCount++;
  

    
    const percentage = (filledCount / totalFields) * 100;
    const scaledPercentage = (percentage / 100) * 20;
    setImagePercentage(scaledPercentage)

  };

  const [pricing,setPricing]=useState({
	"currency": "GHS",
	"price": 0,
	"discount_one_week": 0,
	"discount_two_week": 0,
	"discount_one_month": 0,
	"max_days_allowed": 1,
	"is_party_inclusive": false,
	"party_price": null
	
})


const [service_fee,setService_fee]=useState(
	{

	"cleaning":{
		"category":"non-refundable",
		"charge_per_day":"",
		"charge_per_week":"",
		"charge_per_month":"",
		

	},
	"security_deposit":{
		"category":"refundable",
		"charge_per_day":"",
		"charge_per_week":"",
		"charge_per_month":"",
		

	},
	"service_fee":{
		"category":"non-refundable",
		"charge_per_day":"",
		"charge_per_week":"",
		"charge_per_month":"",
		

	},
	"internet":{
		"category":"non-refundable",
		"charge_per_day":"",
		"charge_per_week":"",
		"charge_per_month":"",
		

	}
	,	"amenity_fee":{
		"category":"non-refundable",
		"charge_per_day":"",
		"charge_per_week":"",
		"charge_per_month":"",
		

	}
	,	

})



const handleServiceFeeChange = (service, field) => (event) => {
	const { value } = event.target;
	setService_fee(prevState => ({
		...prevState,
		[service]: {
			...prevState[service],
			[field]: value
		}
	}));

	console.log(service_fee)
};

const handleChangePrice = (event) => {
    const { name, value } = event.target;
    setPricing(prevState => ({
      ...prevState,
      [name]: value
    }));
	console.log(pricing)

  };



 const [properdetail,setPropertyDetail]=useState({
    "title": "",
    "category_ids": null,
    "listing_property_as": "",
    "latitude": '',
    "longitude": '',
    "map_link": "",
    "address": "",
    "region": "",
	"city": "",
    "bedroom": "",
    "bathroom": "",
    "parking_spot": "",
	"service_fee":[],
    "amenity_ids": [],
    "pet_ids": [],
    "privilege_ids": [],
    "description": "",
    "is_party_inclusive":"",
    "minimum_period": "",
    "pricings": [
      
    ],
	
    "file": "",
    "other_images": []
}) 
  



const [selectedCategories,setSelectedCategories]=useState([])


const handleChange = (event) => {
	const { name, value ,type, checked } = event.target;
	if (name==='amenity_ids' ||name==='privilege_ids'||name==='pet_ids') {
		setPropertyDetail(prevFormData => ({
			...prevFormData,
			[name]: checked
			  ? [...prevFormData[name],  Number(value)]
			  : prevFormData[name].filter(item => item !== Number(value))
		  }));
		} 

		else if (type === 'checkbox' && name === "is_party_inclusive") {
			setPropertyDetail(prevFormData => ({
				...prevFormData,
				[name]: checked ? value : ''
			}));
		}
		
		
	else if (type === 'checkbox') {
	setPropertyDetail(prevFormData => ({
        ...prevFormData,
        [name]: checked
          ? [...prevFormData[name],  value]
          : prevFormData[name].filter(item => item !== value)
      }));
    } 
	else {
      setPropertyDetail(prevFormData => ({ ...prevFormData, [name]: value }));
    }
	
	console.log(properdetail)
	
	
  };
  




  const handleSubmit=async(e)=>{
	e.preventDefault();

	if (e.currentTarget.checkValidity() === false) {
		e.stopPropagation();
		setValidated(true);
	  } 
	 
	 
		  else{

		properdetail.pricings=[pricing]
		properdetail.service_fee=Object.keys(service_fee).map(service => ({
            currency: pricing.currency,
            service: service,
            category: service_fee[service].category,
            charge_per_day: parseFloat(service_fee[service].charge_per_day) || 0,
            charge_per_week: parseFloat(service_fee[service].charge_per_week) || 0,
            charge_per_month: parseFloat(service_fee[service].charge_per_month) || 0
        }));
		if (properdetail.is_party_inclusive==='true'){
			properdetail.is_party_inclusive=true
			properdetail.pricings[0].is_party_inclusive=true
		}
		else{
			properdetail.is_party_inclusive=false
			properdetail.pricings[0].is_party_inclusive=false
		}
		
	
		properdetail.category_ids=(selectedCategories.map(item => item.value))
		properdetail.latitude=latref.current.value
		properdetail.longitude=longref.current.value
		properdetail.address=addressrefvalue.current.value
		properdetail.city=cityrefvalue.current.value 		
		properdetail.map_link=maplinkref.current.value
		properdetail.region=regionref.current.value
		properdetail.other_images=multipleImages
		properdetail.file=singleImages
	console.log(properdetail)
	
	try {
	
	
	console.log(properdetail.category_ids)
	
	// Create a new FormData object
	const formData = new FormData();
	
	// Append each property to the FormData
	formData.append('title', properdetail.title);
	properdetail.category_ids.forEach(id => formData.append('category_ids', id));
	formData.append('listing_property_as', properdetail.listing_property_as);
	formData.append('latitude', properdetail.latitude);
	formData.append('longitude', properdetail.longitude);
	formData.append('map_link', properdetail.map_link);
	formData.append('address', properdetail.address);
	formData.append('region', properdetail.region);
	formData.append('city', properdetail.city);
	formData.append('bedroom', properdetail.bedroom);
	formData.append('bathroom', properdetail.bathroom);
	formData.append('parking_spot', properdetail.parking_spot);
	properdetail.amenity_ids.forEach((id) => formData.append(`amenity_ids`, id));
	properdetail.pet_ids.forEach((id) => formData.append(`pet_ids`, id));
	properdetail.privilege_ids.forEach((id) => formData.append(`privilege_ids`, id));
	formData.append('description', properdetail.description);
	formData.append('is_party_inclusive', properdetail.is_party_inclusive);
	formData.append('minimum_period', properdetail.minimum_period);
;	formData.append('status', 'approved');
	formData.append(`pricings`, JSON.stringify(properdetail.pricings));
	formData.append(`service_fees`, JSON.stringify(properdetail.service_fee));

	if (multipleImages.length > 0) {
		multipleImages.forEach((imageFile, index) => {
			formData.append(`other_images`, imageFile.file);  
		console.log(imageFile.file)
		});
	}
	
	
	// Append nested pricings object
	
	// Append file and other_images
	
	formData.append('file',singleImages[0].file);
	
	
	
	// To check the contents of the FormData object
	
	
		for (let [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		}
	const response =await addproperty(formData).unwrap()
	setPricing({
		"currency": "GHS",
		"price_period": "day",
		"per_person": "",
		"people_allowed": "",
		"is_party_inclusive":false,
		"max_days_allowed":1



		
	})
	setSelectedCategories([]);
    setSingleImages();
    setMultipleImages();
	setService_fee(	{

		"cleaning":{
			"category":"",
			"charge_per_day":"",
			"charge_per_week":"",
			"charge_per_month":"",
			
	
		},
		"security_deposit":{
			"category":"",
			"charge_per_day":"",
			"charge_per_week":"",
			"charge_per_month":"",
			
	
		},
		"service_fee":{
			"category":"",
			"charge_per_day":"",
			"charge_per_week":"",
			"charge_per_month":"",
			
	
		},
		"internet":{
			"category":"",
			"charge_per_day":"",
			"charge_per_week":"",
			"charge_per_month":"",
			
	
		}
		,	"amenity_fee":{
			"category":"",
			"charge_per_day":"",
			"charge_per_week":"",
			"charge_per_month":"",
			
	
		}
	
	})
	setPropertyDetail(({
		"title": "",
		"category_ids": null,
		"listing_property_as": "",
		"latitude": '',
		"longitude": '',
		"map_link": "",
		"address": "",
		"region": "",
		"bedroom": "",
		"bathroom": "",
		"parking_spot": "",
		"amenity_ids": [],
		"pet_ids": [],
		"privilege_ids": [],
		"service_fee":[],
		"description": "",
		"is_party_inclusive":"",
		"minimum_period": "",
		"pricings": [
		  
		],
		
		"file": "",
		"other_images": []
	}) );

	
	navigate('/dashboard/my-properties')
	
	}catch(e){
		console.log(e)
	
	}
	
	
	

	  }



	
  }
  






useEffect(() => {
    calculateBasicPercentage();
	calculateLocationPercentage();
	calculatePropertyDetailsPercentage()
	calculatePricePercentage()
	calculateImagePercentage()
	calculateImagePercentage()
  }, [properdetail, selectedCategories,pricing,singleImages,multipleImages]);




  
  return (

	<div className="container mt-5 mb-md-4 py-5">
	<div>
	<div className="row">
<form  ref={formRef} onSubmit={handleSubmit}   className={` col-lg-8 needs-validation ${validated ? 'was-validated' : ''}`} noValidate  >



	<nav className="mb-3 pt-md-3" aria-label="Breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link  to="/">
							Home
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link to='/dashboard/my-properties'>
							My Properties
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">Add property</li>
				</ol>
			</nav>


			<div className="mb-4">
				<h1 className="h2 mb-0">Add property</h1>
				<div className="d-lg-none pt-3 mb-2">{parseInt(basicInfoPercentage) +parseInt(locationPercentage) +parseInt(propertyDetailPercentage) +parseInt(pricePercentage)+parseInt(imagePercentage)} % content filled</div>
				<div className="progress d-lg-none mb-4" style={{height: '.25rem'}}>
					<div className="progress-bar bg-warning" role="progressbar"  style={{width:`${parseInt(basicInfoPercentage) +parseInt(locationPercentage)  +parseInt(propertyDetailPercentage) +parseInt(pricePercentage) +parseInt(imagePercentage)}%`}} aria-valuenow="41" aria-valuemin="0" aria-valuemax="100"></div>
				</div>
			</div>



{/*basicInfo*/}

			<section className="card card-body border-0 shadow-sm p-4 mb-4" id="basic-info">
				<h2 className="h4 mb-4">
					<i className="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>
					Basic info
				</h2>
				<div className="mb-3">
					<label className="form-label" for="ap-title">Title <span className="text-danger">*</span></label>
					<input className="form-control" onChange={handleChange}  name='title' value={properdetail.title} type="text" id="ap-title" placeholder="Title for your property" maxlength="50"  required />
					<div className="invalid-tooltip mt-1">Please, enter a title</div>

					<span className="form-text">
												50 characters left
					</span>

									</div>
				<div className="row">
					<div className="col-sm-6 mb-3">
						<label className="form-label" for="ap-category">Category <span className="text-danger">*</span></label>
						<MultiSelect
        options={options}
		onChange={setSelectedCategories}
		name="category_ids"
		value={selectedCategories}

		requied
      />
						

											</div>
					<div className="col-sm-6 mb-3">
						<label className="form-label" for="ap-type">Property type <span className="text-danger">*</span></label>
						<select  ref={typref} className="form-select" id="ap-type" required={true} >
							<option value="" disabled  selected hidden>Choose property type</option>
							<option value="rent">Normal Property</option>
							<option value="luxury">Luxury Property</option>
						</select>

											</div>
				</div>
				<div className="form-label fw-bold pt-3 pb-2">You are listing as? <span className="text-danger">*</span></div>
				<div className="form-check">
					<input className="form-check-input"  required={true} onChange={handleChange} checked={properdetail.listing_property_as === 'A registered business'} type="radio" id="ap-company" name="listing_property_as" value="A registered business"/>
					<label className="form-check-label" for="ap-company">A registered business</label>
				</div>
				<div className="form-check">
					<input className="form-check-input"  required={true}   onChange={handleChange}   checked={properdetail.listing_property_as === 'A private property'}  type="radio" id="ap-individual"  name="listing_property_as" value="A private property" />
					<label className="form-check-label" for="ap-individual">A private property</label>
				</div>

							</section>


{/*Location*/}

							<section className="card card-body border-0 shadow-sm p-4 mb-4" id="location">
				<h2 className="h4 mb-4"><i className="fi-map-pin text-primary fs-5 mt-n1 me-2"></i>Location</h2>
				<div  id="formMap">
					<div className="row">

					<div className="col-sm-12 mb-3">
													<label className="form-label" for="ap-address">City <span className="text-danger">*</span></label>

													{isLoaded?
													<Autocomplete
													     
														

														  onLoad={(autocomplete) => (cityref.current = autocomplete)}
        onPlaceChanged={handlePlaceSelected}
        options={{
          types: ['(regions)'],
          componentRestrictions: { country: 'GH' },
        }}
													
																									
													onChange={handleChange}
													 >
          <input requied  ref={cityrefvalue} placeholder="Enter City"    className="form-control"/>
		  </Autocomplete>
       
   : <>
   <input class="form-control" type="text" id="ap-address" value="" disabled required=""/>
   </>

}		


					
      

  



						        <div className="invalid-tooltip mt-1">Please, enter a city</div>


													</div>
						
					

						
						<div className="form-label fw-bold pt-3 pb-2">Display on the map</div>


						


{!isLoaded ? 
	
	  <img className="rounded-3" src={map} alt="Map"/> 
       
   : <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition || center}
        zoom={10}
		
		onClick={handleClickMap}

      >
      
		{markerPosition && <MarkerF position={markerPosition} icon={marker}   />}
      </GoogleMap>

   

}		


<div className="col-sm-5  mt-4">

							<label className="form-label"  for="longitude">Longitude <span className="text-danger">*</span> </label>
						
							<input className="form-control" ref={longref} onChange={handleChange} name='longitude' value={handleChange.longitude} type="text" id="longitude" placeholder="Enter longitude"  required={true} disabled=""/>
							<div className="invalid-tooltip mt-1">Please, a value</div>


													</div>

						
						<div className="col-sm-5   mt-4">
							<label className="form-label"  name='latitude'  for="latitude">Latitude code <span className="text-danger">*</span> </label>
							<input className="form-control" onChange={handleChange} name='latitude' value={handleChange.latitude} ref={latref} type="text" id="latitude" placeholder="Enter Latitude" required={true} disabled=""/>
							<div className="invalid-tooltip mt-1">Please, enter a value</div>


													</div>
						
						<div  className="col-sm-2   mt-4">
							<label className="form-label" for="latitude">Clear </label>

							 <br/>
							<button onClick={()=>{latref.current.value="";longref.current.value=""}} type='button' className="btn btn-danger">X</button>
						</div>
						<div className='col-12 mb-3'>
						<small><i className='fi-info-circle'></i> Select location on the map to display Coordinates.</small>
						</div>

						<div id="gmaps-error" className="error"></div>

						
						<div className="col-sm-12 mb-3">
							<label className="form-label" for="map_link">Map Coordinates <span className="text-danger">*</span></label>
							<input ref={maplinkref} onChange={parseCoordinates}  className="form-control" type="text" id="map_link" placeholder="Enter Map Link"  required={true} disabled=""/>
							<small><i className='fi-info-circle'></i> Select location on the map to display link.</small>

													</div>



													
					</div>  
				</div>
				<div className="mb-3">
										<label className="form-label" for="ap-address">Address <span className="text-danger">*</span></label>


{isLoaded?
													<Autocomplete
													     
														

														 onLoad={(autocomplete) => (addressref.current = autocomplete)}


													
        onPlaceChanged={handlePlaceSelected2}
        options={{
			types: ['address'],
          componentRestrictions: { country: 'GH' },
        }}
													
																									
													onChange={handleChange}
													 >
          <input required    ref={addressrefvalue} onChange={handleChange} name="address"  placeholder="28 Jackson Avenue"  className="form-control"/>
		  </Autocomplete>
       
   : <>
  loading.....
   </>

}	
									</div>
				
			
			<div className="mb-3">
					<label className="form-label" for="ap-type">Select Region <span className="text-danger">*</span></label>
					<input ref={regionref} onChange={handleChange}  name='region'  className="form-control" id="ghana-regions" required >
					
					</input>

									</div>
			</section>


			{/*Property Details*/}



			<section className="card card-body border-0 shadow-sm p-4 mb-4" id="details">
				<h2 className="h4 mb-4"><i className="fi-edit text-primary fs-5 mt-n1 me-2"></i>Property details</h2>
				<div className="mb-4">
					<label className="form-label d-block fw-bold mb-2 pb-1">Bedrooms</label>
					<div className="btn-group btn-group-sm" role="group" aria-label="Choose number of bedrooms">
						
						<input className="btn-check" onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bedroom": 1 }));}} type="radio" id="bedrooms-1" name="bedrooms" value="1" />
						<label className="btn btn-outline-secondary fw-normal"  for="bedrooms-1">1</label>
						<input className="btn-check" type="radio" id="bedrooms-2" onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bedroom": 2 }));}} name="bedrooms" value="2" />
						<label className="btn btn-outline-secondary fw-normal" for="bedrooms-2">2</label>
						<input className="btn-check" type="radio" id="bedrooms-3" onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bedroom": 3 }));}}name="bedrooms" value="3" />
						<label className="btn btn-outline-secondary fw-normal" for="bedrooms-3">3</label>
						<input className="btn-check" type="radio" id="bedrooms-4" onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bedroom": 4 }));}} name="bedrooms" value="4" />
						<label className="btn btn-outline-secondary fw-normal" for="bedrooms-4">4</label>
						<input className="btn-check" type="radio" id="bedrooms-5" onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bedroom": 5 }));}} name="bedrooms" value="5" />
						<label className="btn btn-outline-secondary fw-normal" for="bedrooms-5">5+</label>
					</div>

									</div>
				<div className="mb-4">
					<label className="form-label d-block fw-bold mb-2 pb-1">Bathrooms</label>
					<div className="btn-group btn-group-sm" role="group" aria-label="Choose number of bathrooms">
						<input className="btn-check" onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bathroom": 1 }));}}  type="radio" id="bathrooms-1" name="bathrooms" value="1" />
						<label className="btn btn-outline-secondary fw-normal" for="bathrooms-1">1</label>
						<input className="btn-check"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bathroom": 2 }));}} type="radio" id="bathrooms-2" name="bathrooms" value="2" />
						<label className="btn btn-outline-secondary fw-normal"  for="bathrooms-2">2</label>
						<input className="btn-check" type="radio" id="bathrooms-3"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bathroom": 3 }));}} name="bathrooms" value="3" />
						<label className="btn btn-outline-secondary fw-normal" for="bathrooms-3">3</label>
						<input className="btn-check" type="radio" id="bathrooms-4" name="bathrooms"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "bathroom": 4 }));}}value="4" />
						<label className="btn btn-outline-secondary fw-normal" for="bathrooms-4">4</label>
					</div>

									</div>
				<div className="mb-4">
					<label className="form-label d-block fw-bold mb-2 pb-1">Parking spots</label>
					<div className="btn-group btn-group-sm" role="group" aria-label="Choose number of parking spots">
						<input className="btn-check"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "parking_spot": 1 }));}} type="radio" id="parking-1" name="parking" value="1" />
						<label className="btn btn-outline-secondary fw-normal" for="parking-1">1</label>
						<input className="btn-check" type="radio"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "parking_spot": 2 }));}}  id="parking-2" name="parking" value="2" />
						<label className="btn btn-outline-secondary fw-normal" for="parking-2">2</label>
						<input className="btn-check" type="radio" id="parking-3"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "parking_spot": 3 }));}}  name="parking" value="3" />
						<label className="btn btn-outline-secondary fw-normal" for="parking-3">3</label>
						<input className="btn-check" type="radio" id="parking-4"  onChange={()=>{setPropertyDetail(prevFormData => ({ ...prevFormData, "parking_spot": 4 }));}} name="parking" value="4" />
						<label className="btn btn-outline-secondary fw-normal" for="parking-4">4</label>
					</div>

									</div>
				<div className="mb-4">
					<label className="form-label d-block fw-bold mb-2 pb-1">Amenities</label>
					<div className="row">
					
						
												{

													isLoadingAmenities?
													Array.from({ length: 3 }).map((index)=>{
														return(
															<div className="col-sm-4 " key={index}>
															
															<div className="d-flex " >
									<Skeleton style={{width:"20px",height:"20px",marginRight:"5px"}} />
									<Skeleton style={{width:"70px"}}/>
								</div>
								</div>
														)
													}):
													amenities&&amenities.map((amenity,index)=>{
														return (
															<div className="col-sm-4 " key={index}>
															
															<div className="form-check" >
									<input className="form-check-input" onChange={handleChange}  checked={properdetail.amenity_ids.includes(amenity.id)} type="checkbox" name='amenity_ids' value={amenity.id} />
									<label className="form-check-label" >{amenity.name}</label>
								</div>
								</div>
														)
													})
												}

						
														
					
						
					</div>

									</div>
				
				<div className="mb-4">
					<label className="form-label d-block fw-bold mb-2 pb-1"> Privileges</label>
					<div className="row">
					{

isLoadingPrevileges?
Array.from({ length: 3 }).map((index)=>{
	return(
		<div className="col-sm-4 " key={index}>
		
		<div className="d-flex " >
<Skeleton style={{width:"20px",height:"20px",marginRight:"5px"}} />
<Skeleton style={{width:"70px"}}/>
</div>
</div>
	)
}):
previleges&&previleges.map((previlege,index)=>{
	return (
		<div className="col-sm-4 " key={index}>
		
		<div className="form-check" >
<input className="form-check-input"  onChange={handleChange}   checked={properdetail.privilege_ids.includes(previlege.id)} type="checkbox" name='privilege_ids' value={previlege.id} />
<label className="form-check-label" for="wifi">{previlege.name} allowed</label>
</div>
</div>
	)
})
}
					</div>
				</div>


				<div className="mb-4">
					<label className="form-label d-block fw-bold mb-2 pb-1"> Party</label>
					<div className="row">
	
		<div className="col-sm-4 " >
		
		<div className="form-check" >

		<input className="form-check-input"  onChange={handleChange}   checked={properdetail.is_party_inclusive==='true'} type="checkbox" name='is_party_inclusive' value="true" />
<label className="form-check-label" for="is_party_inclusive">Party Allowed</label>

</div>
</div>

					</div>
				</div>
				<label className="form-label" for="ap-description">Description </label>
				<textarea className="form-control"  name='description'  onChange={handleChange} value={properdetail.description}  id="ap-description" rows="5" placeholder="Describe your property">                    </textarea>
				<span className="form-text">
										1500 characters left
				</span>

							</section>

{/*price*/}

<section className="card card-body border-0 shadow-sm p-4 mb-4" id="price">
				<h2 className="h4 mb-4">
					<i className="fi-cash text-primary fs-5 mt-n1 me-2"></i>
					Pricing 
				</h2>
				
				<div className="d-sm-flex mb-4">
					<div className='w-100 ' style={{display:'flex',flexDirection:"column"}} >
					<label class="form-label " style={{marginBottom:'30px'}} for="pass-visibility">Charge per day  <span className="text-danger">*</span></label>
					<div style={{display:"flex",flexDirection:"row"}}>
	
					<select value={pricing.currency}
          onChange={handleChangePrice}  name="currency"  style={{borderRight:"none",borderTopRightRadius:"0",borderBottomRightRadius:"0",width:'30%'}} className="form-select   mb-2" >
						<option value="GHS">₵</option>
						<option value="USD">$</option>
						
					</select>
					<input  name="price"  onChange={handleChangePrice} style={{marginLeft:'-20px',borderTopLeftRadius:"0",borderBottomLeftRadius:"0",width:'70%'}}  value={pricing.price}
         className="form-control   me-2 mb-2" type="number" id="ap-price" min="0"  required="" placeholder="Enter charge per day"/>
		 
		 </div>
		 </div>

		 <div className=' w-100'>
		 <label class="form-label " for="pass-visibility">Min  Number of days
		 <span className="text-danger">*</span></label>
		 <label class="text-muted fs-xs" >Min Number of days your guests can book</label>


					<input    name="max_days_allowed"       value={pricing.max_days_allowed}
          onChange={handleChangePrice} className="form-control me-2 mb-2" type="number" id="ap-price" min="0"  required=""  placeholder="Enter no of persons allowed"/>
					
					</div>
					
					
					
				</div>
				

				<p className='fs-sm text-muted'>Provide discounts for  these periods  for
				favorable pricing</p>
				<div className="d-sm-flex ">
				
				
					<div className='w-100  ' style={{display:'flex',flexDirection:"column",marginRight:'4px'}} >
					<label class="form-label "  for="pass-visibility">Discount above one Week
					</label>
					


					<input  name="discount_one_week" onChange={handleChangePrice}   value={pricing.discount_one_week}
         className="form-control   me-2 mb-2" type="number" id="ap-price" min="0"  required="" placeholder="Enter discount"/>
		 
	
		 </div>

		 <div className='w-100 ' style={{display:'flex',flexDirection:"column",marginRight:'4px'}} >
					<label class="form-label "  for="pass-visibility">Discount above two Weeks
					</label>
					


					<input  name="discount_two_week" onChange={handleChangePrice}   value={pricing.discount_two_week}
         className="form-control   me-2 mb-2" type="number" id="ap-price" min="0"  required="" placeholder="Enter discount"/>
		 
	
		 </div>

		 <div className='w-100 ' style={{display:'flex',flexDirection:"column"}} >
					<label class="form-label "  for="pass-visibility">Discount above two Weeks
					</label>
					


					<input  name="discount_one_month" onChange={handleChangePrice}   value={pricing.discount_one_month}
         className="form-control   me-2 mb-2" type="number" id="ap-price" min="0"  required="" placeholder="Enter discount"/>
		 
	
		 </div>
					
					
					
				
			
				
				</div>

				<div style={{display:'flex',flexDirection:"row",justifyContent:'space-between',marginTop:"40px"}}>
				<div>
					<span className='.h2'>SERVICE CHARGE</span>
					<p className='text-muted fs-xs'>
					dding service charge will increase total accomodation cost for your guests
					</p>
				</div>
				<div onClick={()=>{setIsServiceChargeOpen(!isServiceChargeOpen)}} class="form-check form-switch mb-2">
                      <input class="form-check-input mt-2" checked={isServiceChargeOpen} id="form-switch-1" type="checkbox"/>

                    </div>
				</div>


<Collapse isOpen={isServiceChargeOpen}>
<div className="row">
                <div className="col-md-3 mb-3">
                    <strong className=''>Service</strong>
                </div>
                <div className="col-md-3 mb-3">
                    <strong className=''>Category</strong>
                </div>
                <div className="col-md-2 mb-3">
                    <strong className=''>Charge/<br /> day</strong>
                </div>
                <div className="col-md-2 mb-3">
                    <strong className=''>Charge/<br />week</strong>
                </div>
                <div className="col-md-2 mb-3">
                    <strong className=''>Charge/<br />month</strong>
                </div>
            </div>

            {Object.keys(service_fee).map(service => (
                <div className="row" key={service}>
                    <div className="col-md-3 mb-3">
                        <p>{service.replace('_', ' ').replace(/^\w/, c => c.toUpperCase())}</p>
                    </div>
                    <div className="col-md-3 mb-3">
                        <select
                            className="form-select fs-sm"
                            id="validationTooltip03"
                            required=""
                            value={service_fee[service].category}
                            onChange={handleServiceFeeChange(service, 'category')}
                        >
                            <option selected hidden disabled>Choose</option>
                            <option value="refundable">refundable</option>
                            <option value="non-refundable">non-refundable</option>
                        </select>
                    </div>
                    <div className="col-md-2 mb-3">
                        <input
                            className="form-control"
                            type="number"
                            min={1}
                            placeholder={`${pricing.currency}`}
                            value={service_fee[service].charge_per_day}
                            onChange={handleServiceFeeChange(service, 'charge_per_day')}
                        />
                    </div>
                    <div className="col-md-2 mb-3">
                        <input
                            className="form-control"
                            type="number"
                            min={1}
                            placeholder={`${pricing.currency}`}
                            value={service_fee[service].charge_per_week}
                            onChange={handleServiceFeeChange(service, 'charge_per_week')}
                        />
                    </div>
                    <div className="col-md-2 mb-3">
                        <input
                            className="form-control"
                            type="number"
                            min={1}
                            placeholder={`${pricing.currency}`}
                            value={service_fee[service].charge_per_month}
                            onChange={handleServiceFeeChange(service, 'charge_per_month')}
                        />
                    </div>
                </div>
			))}
</Collapse>
				

				
								<br/>

				

							</section>
							{/*photos or videos */}

							<section className="card card-body border-0 shadow-sm p-4 mb-4" id="photos">
				<h2 className="h4 mb-4">
					<i className="fi-image text-primary fs-5 mt-n1 me-2"></i>
					Photos / video
				</h2>
				<div className="alert alert-info mb-4" role="alert">
					<div className="d-flex">
						<i className="fi-alert-circle me-2 me-sm-3"></i>
						<p className="fs-sm mb-1">
							The maximum photo size is 20 MB. Formats: jpeg, jpg, png. Put the main picture first and the second is for
							multiple images.
						</p>
					</div>
				</div>
				<label className="form-label" for="ap-image">Main Image <span className="text-danger">*</span></label>
				<div className="file-uploader bg-secondary" style={{ height: '160px',width:"50%" }}>
        <FilePond
         files={singleImages}
          onupdatefiles={setSingleImages}
          allowMultiple={false}
          name="avatar_url"
          labelIdle='<i class="d-inline-block fi-camera-plus fs-2 text-muted mb-2 " Style="margin-top:60px"></i><br><span class="fw-bold"></span>'
          stylePanelLayout="compact"
          styleButtonRemoveItemPosition="left"
          styleButtonProcessItemPosition="right"
          styleLoadIndicatorPosition="right"
          styleProgressIndicatorPosition="right"
          styleButtonRemoveItemAlign={false}
          acceptedFileTypes={['image/png', 'image,/jpeg','image/jpg']}
		  maxFileSize="20MB" /* Max file size */
		  allowImagePreview={true} /* Enable image preview */
        allowImageExifOrientation={true} /* Correct image exif orientation */
        allowFileEncode={true} /* Enable file encoding */
        allowFileTypeValidation={true} /* Enable file type validation */
       
        allowFileSizeValidation={true} /* Enable file size validation */
        />
        <a
          className="filepond--credits"
          aria-hidden="true"
          href="https://pqina.nl/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ transform: 'translateY(152px)' }}
        >
          Powered by PQINA
        </a>
      </div>
								<br/>
				

				<label className="form-label" for="ap-images">Additional Image <span className="text-danger">*</span></label>
				<div className="file-uploader bg-secondary" style={{ height: '160px',width:"50%" }}>
        <FilePond
         files={multipleImages}
          onupdatefiles={setMultipleImages}
          allowMultiple={true}
		
          name="avatar_url3"
          labelIdle='<i class="d-inline-block fi-camera-plus fs-2 text-muted mb-2 " Style="margin-top:60px"></i><br><span class="fw-bold"></span>'
          stylePanelLayout="compact"
          styleButtonRemoveItemPosition="left"
          styleButtonProcessItemPosition="right"
          styleLoadIndicatorPosition="right"
          styleProgressIndicatorPosition="right"
          styleButtonRemoveItemAlign={false}
          acceptedFileTypes={['image/png', 'image/jpeg','image/jpg']}
		  allowImagePreview={true} /* Enable image preview */
        allowImageExifOrientation={true} /* Correct image exif orientation */
        allowFileEncode={true} /* Enable file encoding */
        allowFileTypeValidation={true} /* Enable file type validation */
  
        allowFileSizeValidation={true} /* Enable file size validation */
        />
        <a
          className="filepond--credits"
          aria-hidden="true"
          href="https://pqina.nl/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ transform: 'translateY(152px)' }}
        >
          Powered by PQINA
        </a>
      </div>


				<div class="row" ref={imageref}>

                    </div>

					<div class="row" ref={imageref2}>

                    </div>
							</section>

{/*contacts */}



							{/*Note*/}

							<section className="card card-body border-0 shadow-sm p-4 mb-4" id="note">
				<div className="row">
					<div className="col-sm-12 mb-3">
						<p>Note:
							A 10% transaction fee will be charged on all transactions made by users on the
							platform for this property.
						</p>
					</div>
				</div>
			</section>

			<section className="d-sm-flex justify-content-between pt-2">
			{
				isLoadingAddingProperty?<button type="button" class="btn btn-primary btn-lg mb-2 ">
  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
  saving...
  
  
</button>:
				<button type='submit' className="btn btn-primary btn-lg mb-2" >
					Save and continue
				</button>
			}
				
				
				
			</section>



</form>
<aside style={{  position:'-webkit-sticky',position: 'sticky',top: 0,zIndex: 1020}}  className="  col-lg-3 offset-lg-1 d-none d-lg-block">
			<div style={{  position:'-webkit-sticky',position: 'sticky',top: 0,zIndex: 1020}} className="sticky-top position-sticky pt-5">
				<h6 className="pt-5 mt-3 mb-2">{parseInt(basicInfoPercentage) +parseInt(locationPercentage)+parseInt(propertyDetailPercentage) +parseInt(pricePercentage)+parseInt(imagePercentage)}% content filled</h6>
				<div className="progress mb-4" style={{height: '.25rem'}}>
					<div className="progress-bar bg-warning" role="progressbar" style={{width:`${parseInt(basicInfoPercentage) +parseInt(locationPercentage)+parseInt(propertyDetailPercentage) +parseInt(pricePercentage) +parseInt(imagePercentage)}%`}} aria-valuenow="41" aria-valuemin="0" aria-valuemax="100"></div>
				</div>
				<ul className="list-unstyled">
					<li className="d-flex align-items-center">
													<i className={`fi-check  ${basicInfoPercentage >=20?"text-primary":"text-muted"} me-2`}></i>
												<a className="nav-link fw-normal ps-1 p-0" href="#basic-info" data-scroll="" data-scroll-offset="20">
							Basic info
						</a>
					</li>
					<li className="d-flex align-items-center">
					<i className={`fi-check  ${locationPercentage >=20?"text-primary":"text-muted"} me-2`}></i>
												<a className="nav-link fw-normal ps-1 p-0" href="#location" data-scroll="" data-scroll-offset="20">
							Location
						</a>
					</li>
					<li className="d-flex align-items-center">
													<i className={`fi-check  ${propertyDetailPercentage >=20?"text-primary":"text-muted"} me-2`}></i>
												<a className="nav-link fw-normal ps-1 p-0" href="#details" data-scroll="" data-scroll-offset="20">
							Property details
						</a>
					</li>
					<li className="d-flex align-items-center">
					<i className={`fi-check  ${ pricePercentage >=20?"text-primary":"text-muted"} me-2`}></i>

												<a className="nav-link fw-normal ps-1 p-0" href="#price" data-scroll="" data-scroll-offset="20">
							Price
						</a>
					</li>
					<li className="d-flex align-items-center">
					<i className={`fi-check  ${imagePercentage >=20 ?"text-primary":"text-muted"} me-2`}></i>
												<a className="nav-link fw-normal ps-1 p-0" href="#photos" data-scroll="" data-scroll-offset="20">
							Photos / video
						</a>
					</li>
				
				</ul>
			</div>
		</aside>

	</div>
	</div>

	</div>
  )
}

export default AddProperty