import React,{useState,useEffect} from 'react'
import Sidebar from '../../components/profilecomponents/sidebar'
import { Link } from 'react-router-dom' 
import { useGetMyPropertiesMutation } from '../../features/property/propertySlice'
import MyPropertyItem from '../../components/profilecomponents/myPropertyItem'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function MyProperties() {
const [getmyproperties,{isLoading}]=useGetMyPropertiesMutation()
const [myproperties,setMyProperties] = useState()


const [nextroperties,setNextProperties]=useState()
const [previousProperties,setPreviousProperties]=useState()
const [page,setPage]=useState(1)
const [pageCount,setPageCount]=useState()
const [count,setCount]=useState()


const handlegetMyProperties=async(pageparam)=>{
try{

  const params={
    page:pageparam,
  }

  const properties=await getmyproperties(params)
  console.log(properties.data)
  setMyProperties(properties.data.results)
  setCount(properties.data.count)



  

  console.log("next" ,properties.data.next)
  setNextProperties(properties.data.next)
  setPreviousProperties(properties.data.previous)
  setPageCount(0)
  setPageCount(Math.ceil(properties.data.count / 10))
  console.log("page count: " ,pageCount)


}catch(e){
  console.log(e)
}

}

const handlePagination=(page)=>{
  setPage(page);
  handlegetMyProperties(page);
}


useEffect(()=>{
  handlegetMyProperties(page);
},[])

  return (

       


        <div className="col-lg-8 col-md-7 mb-5">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h1 className="h2 mb-0"></h1>
              {
                count && count>0 ?
                <a className="fw-bold text-decoration-none" href="#"><i className="fi-trash mt-n1 me-2"></i>Delete all</a>
                :
                ""

              }
              
            </div>
            <p className="pt-1 mb-4">Here you can see your property offers and edit them easily.<br/>
           
            </p>
            
            {/* <div className="alert alert-info">
                        No properties found
                  
            </div>*/}
            
            {
              isLoading?
              <>
              <div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>


<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>


<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>


<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>

<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>

<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>

<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>

<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>

<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>

<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>
</>




:

count && count>0 ?
              myproperties && myproperties.map((property,index)=>{
                return(

                  <MyPropertyItem  key={index} property={property} />
                )
              })
              :
              <div class="card card-hover card-horizontal border-0 shadow-sm mb-4">
			<div class="card-body position-relative pb-3">
				<h3 class="h6 mb-2 fs-base">No properties found</h3>
			</div>
		</div>
            }


            {
  pageCount<=1?
  ""
  :

        
            <nav className="mt-2 mb-4" aria-label="Reviews pagination">
              <ul className="pagination">
                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                {
                  previousProperties?
                  <li className="page-item"><span onClick={()=>{handlePagination(page -1)}} className="page-link" href="#" aria-label="Previous"><i className="fi-chevron-left"></i></span></li>
                  :
                  ""


                }
                {
                  page-2>0 && page-2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page - 2)}}>{page-2}</span></li>
                  :""


                }
                {
                 page>0 &&previousProperties?

                  <li className="page-item d-none d-sm-block"><span onClick={()=>{handlePagination(page-1)}} className="page-link" href="#">{page-1}</span></li>
                  :
                  ""


                }
                
                {
                  page>0?
                  <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">{page}<span className="visually-hidden">(current)</span></span></li>
                  :''


                }

                {
                  nextroperties?
                  <li className="page-item d-none d-sm-block"><span className="page-link" onClick={()=>{handlePagination(page+ 1)}}>{page+1}</span></li>

                  :""

                }
                {
                  page+2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page + 2)}}>{page+2}</span></li>
                  :""


                }

                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block">...</li>
                  
                  :""
                }
                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link"  onClick={()=>{handlePagination(pageCount&&pageCount)}} href="#"> {pageCount&&pageCount}</span></li>
                  
                  :"" 
                }
                {
                  nextroperties?
                  <li className="page-item"><span  onClick={()=>{handlePagination(page + 1)}} className="page-link" href="#" aria-label="Next"><i className="fi-chevron-right"></i></span></li>
                  :
                  ""

                }
                
                
              </ul>
            </nav>

            }
      

  

            </div>

  )
}

export default MyProperties