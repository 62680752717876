import React, { useState,useRef,useEffect } from 'react'
import { useChangeBookingHostMutation} from '../../features/property/propertySlice';
import { Link,useNavigate } from 'react-router-dom';
import { differenceInDays, differenceInWeeks, differenceInMonths,format } from 'date-fns';


function BookedItem({property,booking,handlegetBookingList}) {
    const [showdropdown,setShowDropdown]=useState(false);
    const [showmodal,setShowModal]=useState(false);
    const dropdownRef = useRef(null);
    const modalRef = useRef(null);
    const [changebooking,{isLoading}]=useChangeBookingHostMutation()
    const navigate=useNavigate()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [difference, setDifference] = useState({ days: 0, weeks: 0, months: 0 });
    const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const [declinespinner,setDeclinespinner] = useState(false);
  const [accceptspinner,setAcceptspinner] = useState(false);


    const calculateDifference = () => {
      const formattedStart = format(booking.check_in, 'MMMM d, yyyy');
      const formattedEnd = format(booking.check_out, 'MMMM d, yyyy');
      setFormattedStartDate(formattedStart);
      setFormattedEndDate(formattedEnd);
      const start = new Date(booking.check_in);
      const end = new Date(booking.check_out);
      const days = differenceInDays(end, start);
      const weeks = differenceInWeeks(end, start);
      const months = differenceInMonths(end, start);
  
      setDifference({ days, weeks, months });
    };
  
   
const handleAccept=async(e)=>{
  setAcceptspinner(true);
  try{
    const body={status:"approved"}
    const response = await  changebooking({id:booking.id,body:body}).unwrap()
    console.log(response)
    setAcceptspinner(false);
    handlegetBookingList()
  }
  catch{
    console.log(e)
    setAcceptspinner(false);
  }
}


const handleDecline=async(e)=>{
  setDeclinespinner(true);
  try{
    const body={status: "declined"}
    const response = await  changebooking({id:booking.id,body:body}).unwrap()
    setDeclinespinner(false);
    console.log(response)
  }
  catch{
    setDeclinespinner(false);
    console.log(e)
  }
}

    useEffect(() => {
      calculateDifference();
      function handleClickOutside(event) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setShowDropdown(false);
          }

          if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };

  }, []); 







  return (
<>
    
  <div className={`backdrop  ${showmodal?"show":""} `}>
      <div  class="modal fade show open-modal" id="modalDefault" tabindex="-1" style={{display: 'block'}} aria-modal="true" role="dialog">
            <div ref={modalRef} class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Details</h4>
                  <button  onClick={()=>{setShowModal(!showmodal)}} class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p>
                  <div className='mb-2'>
                  <strong>Property:</strong>
                  {" "+property.title}
                  </div>
                  
                  <div className='mb-2'>
                  <strong>User:</strong>
                  {booking.user.first_name}
                  { " "+booking.user.last_name}
                  </div>
                 

                  <div className='mb-2'>
                  <strong>Total Amount:</strong>
                  {booking.price.currency}{" "+booking.total_amount}
                  </div>
                  <div className='mb-2'>
                  <strong>Duration:</strong>
                  {difference.days>0?difference.days>1?difference.days +" days":difference.days+" day" :""}

                  {difference.weeks>0?difference.weeks>1?difference.weeks +" weeks":difference.weeks +" week" :""}


                  {difference.months>0?difference.months>1?difference.months +" months":difference.months+" month" :""}

                  </div>

                  <div className='mb-2'>
                  <strong>checkin Date:</strong>
                  {formattedStartDate}
                  </div>

                  <div>
                  <strong>checkout  Date:</strong>
                  {formattedEndDate}
                  </div>

                   </p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal "  onClick={()=>{setShowModal(!showmodal)}}>Ok</button>

                 
                </div>
              </div>
            </div>
          </div>
          </div>

  


                    <div class="card card-hover card-horizontal border-0 shadow-sm mb-4 "  >
                      <div class="card-img-top card-img-hover" style={{backgroundImage: `url(https://dev-api.shortstaybuddy.com${property.files})`}}>
                      
                      </div>
                      <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between pb-1"><span class="fs-sm text me-3">{formattedStartDate+ " -- "+formattedEndDate}</span>
                        <div className="dropdown position-absolute zindex-5 top-0 end-0 mt-3 me-3">
      <button onClick={()=>{setShowDropdown(!showdropdown)}} className="btn btn-icon btn-light btn-xs rounded-circle shadow-sm" type="button" id="contextMenu1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fi-dots-vertical"></i></button>
      <ul ref={dropdownRef} className={`dropdown-menu my-1 ${showdropdown?"show":""}`} aria-labelledby="contextMenu1" style={{right:0}}>
     
      <button onClick={()=>{setShowModal(!showmodal)}} className="dropdown-item" type="button"><i className="fi-eye-on opacity-60 me-2"></i>View details</button>

  
       
      </ul>
    </div>
                        </div>
                        <h3 class="h6 mb-1"><a class="nav-link" href="#">{property.title.length>20?property.title.slice(0,20)+"...":property.title}</a></h3>
                        <div class="text-primary fw-bold mb-1">  {booking.price.currency}{" "+booking.total_amount}</div>
                        <div class="fs-sm text opacity-70"><i class="fi-user me-1"></i>booked by:{booking.user.first_name + booking.user.last_name} </div>
                        <div class="border-top border mt-3 pt-3">
                          <div class="row g-2">
                            <div class="col me-sm-1">
                            {
                              accceptspinner?
                              <div onClick={handleAccept} class="btn btn-success rounded text-center w-100 h-100 p-2"><span class="fs-xs text">
                              <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>

                              Accepting...</span></div>
                              :
                              <div onClick={handleAccept} class="btn btn-success rounded text-center w-100 h-100 p-2"><span class="fs-xs text">Accept</span></div>

                            }

                            </div>
                            <div class="col me-sm-1">
                            {
                              declinespinner?
                              <div  onClick={handleDecline} class="btn btn-danger rounded text-center w-100 h-100 p-2"><span class=" text-">
                              <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>

                              Declining...</span></div>

                              :
                              <div  onClick={handleDecline} class="btn btn-danger rounded text-center w-100 h-100 p-2"><span class=" text-">Decline</span></div>

                            }
                            </div>
                           
                          </div>
                        </div>
                      </div>
                  
                  </div>


</>
  )
}

export default BookedItem