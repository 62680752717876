import React from 'react'
import { useLocation,Navigate,Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken ,selectIsNumberVerified} from '../features/auth/authSlice'

function RequireOtp() {
    const is_number_verified =useSelector(selectIsNumberVerified)
    

    const location=useLocation()
 // If the number is not verified, redirect to "/verify"
 if (is_number_verified==='false') {
    return <Navigate to="/verify" state={{ from: location }} replace />;
}

// If the number is verified, render the child components (Outlet)
return <Outlet />;
}

export default RequireOtp