import React from 'react'
import { Link } from 'react-router-dom'

function Termsandconditions() {
  return (

    <div className='container-fluid mt-5 pt-5 p-0'>


<div class="container ">
		<nav class="mb-3 pt-md-3" aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><Link  to='/'>Home</Link></li>
				<li class="breadcrumb-item active" aria-current="page">
					Terms and Conditions
				</li>
			</ol>
		</nav>
	</div>

    <section class="container mb-5 pb-2">
    <div class="row align-items-center justify-content-center">
    
        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h1 class="mb-4">
                Terms and Conditions
            </h1>
            <h4 class="mb-4">
                1. Introduction
            </h4>
            <p class="mb-4 pb-3 fs-lg">
            Short Stay Buddy (the “Website”) is a platform that connects property owners or managers
                (“Hosts”) who wish to rent out their properties (“Listings”) with individuals seeking to rent
                such properties for short-term stays (“Guests”). This Terms of Service Agreement
                (“Agreement”) governs the use of the Website and the services provided through the
                Website (collectively, the “Services”) by Guests, Hosts, and any other users of the Website
                (collectively, “Users”).
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                2. Eligibility
            </h4>
            <p class="mb-4 pb-3 fs-lg">
                To use the Services, you must be at least 18 years old and have the legal capacity to enter
                into a binding agreement. If you are using the Services on behalf of an organization, you
                represent and warrant that you have the authority to bind that organization to this
                Agreement.

            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                3. Listing Information
            </h4>
            <p class="mb-4 pb-3 fs-lg">
                Hosts are solely responsible for the accuracy and completeness of their Listings. Accra
                Short Stays does not guarantee the accuracy or completeness of any Listing information and
                is not responsible for any inaccuracies or omissions in Listings.
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                4. Reservations and Payments
            </h4>
            <p class="mb-4 pb-3 fs-lg">
                Guests may reserve a Listing through the Website by completing the reservation process
                and making payment through the Website. The Host and Guest will enter into a direct (legal)
                relationship upon completion of the reservation. Accra Short Stays is not a party to any
                agreement between Hosts and Guests and is not responsible for any disputes between
                them.We however have a dedicated dispute resolution team to help out with any disputes
                that may arise.
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                5. Cancellation Policy
            </h4>
            <p class="mb-4 pb-3 fs-lg">
                Cancellation policies for Listings may vary, and Guests should review the cancellation policy
                for before making a reservation. If a Guest cancels a reservation, Accra Short Stays will
                refund the Guest in accordance with the applicable cancellation policy.
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                6. Fees
            </h4>
            <p class="mb-4 pb-3 fs-lg">
            Short Stay Buddy does note charge Guests a fee for use of the Services. Accra Short Stays
                also charges Hosts a fee for use of the Services, which may be a percentage of the
                reservation cost or a flat fee. All fees are non-refundable.
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                7. Proprietary Rights
            </h4>
            <p class="mb-4 pb-3 fs-lg">
                The Website and its content are protected by copyright, trademark, and other proprietary
                rights. You may not use the Website or its content for any commercial purposes without
            Short Stay Buddy's express written consent.
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                8. Disclaimer of Warranties
            </h4>
            <p class="mb-4 pb-3 fs-lg">
            Short Stay Buddy MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH
                RESPECT TO THE SERVICES OR THE WEBSITE, INCLUDING WITHOUT LIMITATION
                ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, WARRANTY OF
                MERCHANTABILITY, OR WARRANTY AGAINST INFRINGEMENT. ACCRA SHORT STAYS
                DOES NOT WARRANT THAT THE SERVICES OR THE WEBSITE WILL MEET YOUR
                REQUIREMENTS OR THAT THE SERVICES OR THE WEBSITE WILL BE
                UNINTERRUPTED or ERROR-FREE.
            </p>
        </div>

        <div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h4 class="mb-4">
                9. Limitation of Liability
            </h4>
            <p class="mb-4 pb-3 fs-lg">
            Short Stay Buddy WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY
                DAMAGES ARISING OUT OF YOUR USE OF THE SERVICES OR THE WEBSITE,
                INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS.
            </p>
        </div>

    </div>
</section>
</div>
  )
}

export default Termsandconditions